$growlPadding: $size-default;
$growlMargin: 1em;
$growlDistance: 110%;

@keyframes growlShow {
  0% {
    overflow: hidden;
    opacity: 0;
    transform: translate3d($growlDistance, 0, 0);
    max-height: 0;
  }
  66% {
    overflow: hidden;
    opacity: 1;
    transform: translate3d($growlDistance, 0, 0);
    max-height: 500px;
  }
  100% {
    padding-top: $growlPadding;
    padding-bottom: $growlPadding;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
    max-height: none;
  }
}

@keyframes growlDismiss {
  0% {
    transform: translate3d(0, 0, 0);
    margin-bottom: $growlMargin;
  }
  44% {
    overflow: hidden;
    transform: translate3d($growlDistance, 0, 0);
    max-height: 500px;
    opacity: 1;
    padding-top: $growlPadding;
    padding-bottom: $growlPadding;
    margin-bottom: $growlMargin;
  }
  100% {
    overflow: hidden;
    transform: translate3d($growlDistance, 0, 0);
    max-height: 0;
    opacity: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

@keyframes growlCenterDismiss {
  100% {
    opacity: 0;
  }
}

.fsa-growl-container {

  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1003;

  // max-height: calc(100vh - #{$growlPadding});
  max-height: 100%;
  max-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;

  @include breakpoint(M) {
    left: auto;
    width: 364px;
  }

  &--centered {

    max-height: 100%;
    z-index: 1002;
    animation: none;

    @include breakpoint(L) {
      $topish: ($size-extra-large * 3);
      position: fixed;
      left: 50%;
      top: $topish;
      max-height: 100%;
      max-height: calc(100vh - #{$topish});
      top: 20vh;
      transform: translate(-50%, 0);
    }

  }

  &[aria-hidden="true"] {
    display: none;
  }

}

.fsa-growl {

  & {
    pointer-events: all;
    box-shadow: 0 $size-base $size-small 0 rgba(0, 0, 0, .25);
    margin: $growlMargin;
    padding: $growlPadding;
    background-color: $color-fsa-aqua-100;
    border: 1px solid $color-fsa-aqua;
    border-left-width: 4px;
    animation: .66s growlShow ease-in-out both;
  }

  &[aria-hidden="true"] {
    display: none;
  }

  &--centered {

    animation: none;
    visibility: visible;
    opacity: 1;

    // scss-lint:disable SelectorFormat
    &.fsa-growl--dismissing {
      animation: .23s growlCenterDismiss both;
    }

  }

  &--dismissing {
    animation: .66s growlDismiss both;
  }

  &--error {
    background-color: $color-fsa-red-100;
    border-color: $color-fsa-red;
  }

  &--warning {
    background-color: $color-fsa-yellow-100;
    border-color: $color-fsa-yellow;
  }

  &--success {
    background-color: $color-fsa-primary-100;
    border-color: $color-fsa-primary;
  }

  &__hd {
    position: relative;
  }

  &__close {

    @include fsa-button-reset;
    width: $size-extra-large;
    height: $size-extra-large;
    position: absolute;
    top: -$growlPadding;
    right: -$growlPadding;
    padding: ($growlPadding * 1.5);

    background-image: url('#{$image-path}/close.svg');
    background-position: center;
    background-repeat: no-repeat;

    &:active {
      opacity: .6;
    }

  }

  &__close-icon {
    display: none;
  }

  &__title {
    @include fsa-header('3');
    margin-top: 0;
    margin-bottom: .5em;
    font-family: $font-sans;
    padding-right: $size-medium;
    color: $color-base;
  }

  &__bd {

    padding-right: $size-medium;
    font-size: $font-size-2;

    :first-child {
      margin-top: 0;
    }

    p {
      margin-bottom: .5em;
    }

  }

  &:focus {
    outline: 0;
  }

}
