.detailSection {
  margin-bottom: 24px;
}

.detailSectionTitle {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  padding: 8px 10px;
  border-radius: 5px;
  font-weight: 600;
  background: #e2e2e2;

  button {
    margin-right: 12px;
    padding-top: 0;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 0;
    height: 32px;
    background-color: #e2e2e2;
    color: #205493;
    /* border: 0.75px solid #205493; */
    border-radius: 1px;
    border: 1px solid #1f5492;
  }
}

.detailSectionContent {
  border-radius: 5px;
  background: rgba(235, 235, 235, 0.42);
  .metaItem {
    // margin-bottom: 12px;
    padding: 3px 12px;
  }
  input[type='date'],
  input[type='datetime-local'],
  input[type='month'],
  input[type='time'] {
    -webkit-appearance: listbox;
    height: 30px;
    margin-top: 5px;
  }
}

.metaItem {
  .metaName {
    margin-right: 8px;
    font-weight: bold;
  }
}

.recordsManagementInnerRowBtn {
  margin-top: 8px;
  display: flex;
  justify-content: end;
}
