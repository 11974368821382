.scan-outer-div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.scan-inner-div {
    width: 100%;
    background: white;
}
.upload-file-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px;
}
.drop-files-section {
    border: 2px dashed gray;
    padding: 5rem;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.file-upload-msg{
    color: green;
    font-size: 20px;
}

.refresh-icon-outer-div{
    display: flex;
    justify-content: end;
    margin-right: 2rem;

    .fa-refresh{
        font-size: 3rem;
        cursor: pointer !important;
    }
}

.fileTable {
    margin-top:1.5rem !important;
    table {
        margin: 0rem !important;
        table-layout: fixed; 
    }
    th {
        padding: 5px 5px !important;
        width: 25%;
        border: 1px solid black;
        font-weight: 700;
        background: rgb(35, 100, 48);
        color: white;
    }
    td {
        padding-bottom: 0px !important;
        width: 25%;
        border: 1px solid black;
        word-break: break-all;
        padding: 5px 5px !important;
    }
    .border-black {
        // border: 2px solid #000;
    }
    input {
        height: 30px;
        margin: 7px;
    }
    tbody {
        display: block;
        height: 60rem;
        overflow-y: auto;
        overflow-x: hidden;
    }
    thead, tbody tr {
        display: table;
        width: 100%;
    }
}

.file-upload-table{
    .spinner-outer-div{
        position: absolute;
        left: 0;
        right: 0;
        margin-left: 40rem;
        margin-right: auto;
        margin-top: 10rem;
        align-items: center;
    }
}