.level-one-heading {
  position: absolute;
  top: -100px;
  height: 0px;
  color: red;
}

.headIcon {
  color: rgb(68, 114, 196);
  font-size: 16px;
}

.headText {
  color: black;
  font-size: 13px;
  margin: 0px 5px;
}

.fsa-tophat__subagency .headTextOuterDiv {
  font-size: 14px;
  margin: 0px 5px;
}

.messageCount {
  position: relative;
  top: -10px;
  right: 0px;
  background: red;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    color: #fff;
    font-weight: 800;
  }
}

// Rushikesh scrollbar start
.popover {
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: green;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #15b300;
  }
}

// Rushikesh scrollbar end

// message popUp head start
.message-btn {
  border: none;
  background-color: initial;
}

.popBody {
  border: 1px solid grey;
  padding: 5px 5px;
  border-radius: 10px;
  margin-bottom: 5px;

  &__date {
    color: grey;
    margin: 0 5px;
    text-align: right;
    padding: 2px;
  }

  &__Btn {
    color: grey;
    margin-left: 5px;
  }

  &__undoBtn {
    border: none;
    background-color: grey;
    color: #fff;
    border-radius: 3px;
    margin-left: 3px;
    height: 20px;
  }
}

// message popUp head end
.user_guide_icon {
  width: 33px;
  margin-right: 10px;
}