.fsa-breadcrumb {

  & {
    margin: $size-medium 0 0;
  }

  &__list {
    margin: 0;
    padding: 0;
  }

  &__item {

    @include font-size(1);
    margin: 0;
    display: inline-block;

    @include breakpoint(M) {
      @include font-size(2);
    }

    &:before,
    &:after {
      content: '';
      display: none;
      margin: 0;
    }

    &:after {
      content: '/';
      display: inline-block;
      margin-right: $size-base;
      margin-left: $size-base;
    }

    &:last-child {

      &:after {
        display: none;
      }

    }

    &:only-child {

      & {
        display: inline-block;
        align-items: center;
      }

      &:after {
        display: none;
      }

    }

  }

  &__link {

    & {
      color: $color-fsa-link;
      text-decoration: none;
      display: inline-flex;
      align-items: center;
    }

    &:visited,
    &:hover {
      color: $color-fsa-link;
    }

    &:before {
      .fsa-breadcrumb__item:only-child & {
        content: '';
        display: block;
        width: $size-default;
        height: $size-default;
        margin-right: $size-base;
        background-image: url('#{$image-path}/chevron-left--link.svg');
        background-size: 100%; // fallback
        background-size: contain;
      }
    }

  }

}
