.updateHoldModal {

    .modalHeader {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;

        .headingText {
            color: #484440;
            font-family: Poppins;
            font-size: 22px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.44px;
            margin-left: 2rem;
        }

        .modalHeaderTitle {
            margin: 0;
            font-size: 24px;
            font-weight: 550;
            color: #484440;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.44px;
            font-family: sans-serif;
        }

        .modalHeaderIcon {
            cursor: pointer;

            .resizeIcon {
                margin-right: 1rem;
            }
        }
    }

    .updateHoldModalBody {
        padding: 0 2rem;
        margin: 1rem 1rem;





    }

    .createRecordBtnStyle {
        background: white;
        color: #1F5492;
        border: 1px solid #1F5492;
        font-weight: 600 !important;
    }

    .updateHoldFooter {
        padding: 0px !important;

        .footerInternalDiv {
            width: 100%;
            height: 53px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .saveRecordBtn {
            background: #215597 !important;
            color: white !important;
            border-color: transparent !important;
            border-radius: 3.38px;
        }

        .cancelRecordBtn {
            background: white;
            color: #1F5492;
            border: 1px solid #1F5492;
        }
    }

}