.docModalHeaderSection {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  .docModalHeaderText {
    margin: 0;
    font-size: 24px;
    font-weight: 550;
    color: #484440;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.44px;
    font-family: sans-serif;
  }

  .docModalHeaderIcon {
    cursor: pointer;
  }
}

.documentErrorModal {
  .docModalBody {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
  }

  .modalImageDiv {
    margin: 10px 5px;
    position: relative;
    .docModalCircleIcon {
      // height: 11rem;
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
    .errorModalTriangleIcon {
      position: absolute;
      top: 16%;
      left: 18%;
      height: 6rem;
    }
  }

  .docModalText {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .docModalDescription {
    color: #eb5757;
    font-size: 1.9rem;
    font-weight: 500;
    letter-spacing: 0.8px;
    margin-top: 0.6rem;

    .errorModalHeading {
      margin-inline: 1rem;
    }
  }

  .docModalMessage {
    color: rgba(0, 0, 0, 0.9);
    background-color: rgba(116, 140, 168, 0.12);
    font-size: 1.6rem;
    font-weight: 400;
    letter-spacing: 0.5px;
    margin: 1.7rem 1.3rem 1rem 1.3rem;

    padding: 0.8rem 1.7rem 0.8rem 1.7rem;

    .errorModalNote {
      font-weight: 600;
    }
  }
}

.docModalFooter {
  height: 53px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;

  .tryAgainButton {
    color: #1f5492;
    font-weight: 400;
    margin-left: 10px;
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid #1f5492;
  }
}
