$progress: fsa-progress;
$progress-height: $size-base;
$progress-bg-color: $color-fsa-aqua-100;
$progress-fill-color: $color-fsa-aqua-200;
$progress-fill-color-dark: $color-fsa-aqua;
$progress-fill-color-complete: $progress-fill-color-dark;

.#{$progress}-inline {
  display: inline-block;
}

.#{$progress} {

  & {
    display: flex;
    flex-direction: column;
    flex-flow: column-reverse;
  }

  &__details {
    display: flex;
    padding-top: $progress-height;
    margin-bottom: $size-default;
    clear: both;
  }

  &__label {}

  &__value {
    margin-left: auto;
  }

  &__bar {
    background-color: $progress-bg-color;
    display: block;
    overflow: hidden;
    height: $progress-height;
    margin-bottom: -$progress-height;
    position: sticky !important;
    top: 0;
    z-index: 3;
    float: left;
    width: 100%;

    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
      position: relative;
    }

  }

  &__primary,
  &__secondary {

    & {

      height: 100%;
      position: absolute;
      width: 100%;
      animation: none;
      transform-origin: top left;
      transition: transform 250ms ease;
      transform: scaleX(0);
      overflow: hidden;

      .#{$progress}--indeterminate & {
        transition: none;
      }

    }

    &::after {

      display: inline-block;
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      background: linear-gradient(45deg, $progress-fill-color, $progress-fill-color-dark, $progress-fill-color);
      background-size: 600% 600%;

      .#{$progress}--indeterminate & {
        animation: none;
        background: $progress-fill-color;
        background: linear-gradient(45deg, $progress-fill-color, $progress-fill-color-dark);
      }

    }

    &[style*="scalex( 1"],
    &[style*="scaleX( 1"],
    &[style*="scalex(1"],
    &[style*="scaleX(1"] {
      border: $size-base solid $progress-fill-color-dark;
    }

    @media (-ms-high-contrast:active) {
      border-top: solid $size-base;
    }

  }

  &__primary {

    & {}

    .#{$progress}--indeterminate & {
      backface-visibility: hidden;
      animation: #{$progress}-primary-indeterminate-translate 1.66s infinite linear;
      left: -145.166611%;
    }

    .#{$progress}--indeterminate &::after {
      backface-visibility: hidden;
      animation: #{$progress}-primary-indeterminate-scale 1.66s infinite linear;
    }

  }

  &__secondary {

    & {
      display: none;
    }

    .#{$progress}--indeterminate & {
      display: block;
      backface-visibility: hidden;
      animation: #{$progress}-secondary-indeterminate-translate 1.66s infinite linear;
      left: -54.888891%;
    }

    .#{$progress}--indeterminate &::after {
      backface-visibility: hidden;
      animation: #{$progress}-secondary-indeterminate-scale 1.66s infinite linear;
    }

  }

}

@keyframes #{$progress}-primary-indeterminate-translate {
  0% {
    transform: translateX(0);
  }

  20% {
    animation-timing-function: cubic-bezier(.5, 0, .70173, .49582);
    transform: translateX(0);
  }

  59.15% {
    animation-timing-function: cubic-bezier(.30244, .38135, .55, .95635);
    transform: translateX(83.67142%);
  }

  100% {
    transform: translateX(200.61106%);
  }
}

@keyframes #{$progress}-primary-indeterminate-scale {
  0% {
    transform: scaleX(.08);
  }

  36.65% {
    animation-timing-function: cubic-bezier(.33473, .12482, .78584, 1);
    transform: scaleX(.08);
  }

  69.15% {
    animation-timing-function: cubic-bezier(.06, .11, .6, 1);
    transform: scaleX(.66148);
  }

  100% {
    transform: scaleX(.08);
  }
}

@keyframes #{$progress}-secondary-indeterminate-translate {
  0% {
    animation-timing-function: cubic-bezier(.15, 0, .51506, .40969);
    transform: translateX(0);
  }

  25% {
    animation-timing-function: cubic-bezier(.31033, .28406, .8, .73371);
    transform: translateX(37.65191%);
  }

  48.35% {
    animation-timing-function: cubic-bezier(.4, .62704, .6, .90203);
    transform: translateX(84.38617%);
  }

  100% {
    transform: translateX(160.27778%);
  }
}

@keyframes #{$progress}-secondary-indeterminate-scale {
  0% {
    animation-timing-function: cubic-bezier(.15, 0, .51506, .40969);
    transform: scaleX(.08);
  }

  19.15% {
    animation-timing-function: cubic-bezier(.31033, .28406, .8, .73371);
    transform: scaleX(.4571);
  }

  44.15% {
    animation-timing-function: cubic-bezier(.4, .62704, .6, .90203);
    transform: scaleX(.72796);
  }

  100% {
    transform: scaleX(.08);
  }
}

@keyframes #{$progress}-background-scroll {
  to {
    transform: translateX(-$size-small);
  }
}
