.userInfoMainContent {

    width: calc(100% - 90px);
    height: calc(95vh - 65px);
    display: flex;
    flex-direction: column;
    align-items: center;

    .userInfoContent {
        display: contents;

        .userInfoIcon {
            width: 12rem;
            margin-top: 5rem;
        }

        .userProfileText {

            color: #1F5492;
            text-align: center;
            font-family: Poppins;
            font-size: 2.5rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.587px;
            margin: 1rem 0;

        }
    }

    .userDetails {
        border-radius: 8px;
        background: #FFF;
        box-shadow: 0px 4px 4px 0px rgba(116, 116, 116, 0.04);
        margin-bottom: 3rem;
        padding: 2rem;
        width: 100rem;
        max-width: 100%;

        .inputRow {
            display: flex;
            justify-content: space-between;
            margin-bottom: 1rem;
        }

        .inputSection {
            display: flex;
            flex-direction: column;
            // width: 25rem;

            .userLabel {
                color: #0A96D1;
                font-family: Poppins;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.32px;
            }

            .userInput {
                border: 0;
                /* outline: 0; */
                border-bottom: 2px solid rgba(84, 84, 84, 0.15);
                padding: 0px;
                color: var(--Color-Blue, #2E3E5C);
                font-family: Poppins;
                font-size: 22px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: 0.32px;
                background-color: white;
            }
        }
    }

    .editUserInfoBtn {
        border-radius: 5px;
        border: 1px solid #1F5492;
        background: #1F5492;
        color: white;
        width: 35rem;
        height: 4rem;
    }


}