.tableHeaderWithCheckbox {
    cursor: pointer;
    text-align: center;
    background-color: #12A5E4;
    cursor: default;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    .tableCheckboxInput {
        cursor: pointer;
        width: 1.8rem; 
        height: 1.8rem; 
        // margin-left: 1rem;
    }

    .tableCheckboxTitle {
        font-weight: 400;
        color: white;
        font-size: 16px;
        font-family: sans-serif;
        // flex: 1;
    }

    input[type='checkbox']:checked {
        accent-color: #1f5492;
    }
}

.tableHeaderStyle {
    text-align: center;
    background-color: #12A5E4;
    cursor: pointer;
    // margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    padding: 8px 0px;
}
.tableHeaderTitle {
    font-weight: 400;
    color: white;
    font-size: 16px;
    font-family: sans-serif;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.disabledHeaderStyle{
    text-align: center;
    background-color: #12A5E4;
    cursor: default;
    pointer-events: none;
    display: flex;
    justify-content: center;
    padding: 8px 0px;
}

.tableCheckboxInput {
    cursor: pointer;
    width: 1.8rem; 
    height: 1.8rem; 
}
.checkboxOuterWrapper input[type='checkbox']:checked {
    accent-color: #1f5492;
}