.fsa-screen-id {

  $bgcolor: shade($color-fsa-tertiary-300, 15%); //

  & {

    padding-left: $size-default;
    padding-right: $size-default;
    background-color: $bgcolor;
    margin: 0;

    @media print {
      box-shadow: none !important;
      border-top: 1px solid $color-fsa-tertiary-100;
      background-color: transparent;
      padding: initial;
      padding-top: .5em;
      margin: 2em 0 0;
    }

    &--fullscreen {
      @include breakpoint(L) {
        padding-left: $size-medium;
        padding-right: $size-medium;
      }
    }

  }

  .fsa-footer + & {
    box-shadow: 0 100vh 0 100vh $bgcolor;
  }

  &__bd {

    @include font-size(2);
    color: $white;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    padding: $size-small 0;
    max-width: $site-max-width;

    .fsa-screen-id--fullscreen > & {
      max-width: none;
    }

    @media print {
      color: initial;
      max-width: none;
    }

  }

  &__dl {
    margin: 0;
  }

  &__dt {

    display: inline-block;

    &:after {
      content: ':';
    }

  }

  &__dd {
    display: inline-block;
    margin-left: $size-small;
  }

}
