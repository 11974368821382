.aliceSenseTabBodyContent {
  // overflow-y: scroll !important;
  // max-height: 600px;
  min-height: 460px;
  scrollbar-width: none;

  .aliceSenseHeadingFont {
    color: #000;
    font-family: Poppins;
    font-size: 1em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.24px;
  }

  .aliceSenseBodyPanel {
    padding: 9px 4px;
    border-bottom: 1px solid #dae1ff;

    .summaryText {
      color: rgba(61, 90, 140, 0.77);
      font-family: Poppins;
      font-size: 0.7em;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.216px;
    }

    .aliceSenseNormalFont {
      color: #3d5a8c;
      font-size: 0.9em;
      font-weight: 500;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.28px;
      font-family: sans-serif;
    }

    .bodyPanelThirdIcons {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 3px;
      // justify-content: space-between;
      // overflow-x: auto;
      .bodyPanelFaceIcon {
        img {
          // width: 38.03px;
          width: 4rem !important;
        }

        align-items: center;
        display: flex;
        flex-direction: column;
      }
    }
  }

  .senseBodyTinyFont {
    color: #3d5a8c;
    font-weight: 700;
    font-style: normal;
    text-align: center;
    // font-size: 7.286px;
    line-height: normal;
    font-family: sans-serif;
    letter-spacing: 0.146px;
    font-size: 0.8rem;
    margin-top: 4px;
  }

  .aliceBodyPanelIndicatorIcon {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    img {
      width: 32px;
      height: 37px;
    }
  }

  .aliceBodyPanelFlagIcon {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    img {
      width: 35.909px;
      height: 35.909px;
    }
  }

  .aliceBodyPanelCircleIcon {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    img {
      width: 36.43px;
    }
  }

  .aliceBodyPanelLast {
    display: flex;
    padding: 10px 4px;
    // margin-bottom: 70px;
    justify-content: space-between;
    border-bottom: 1px solid #dae1ff;
    flex-wrap: wrap;
  }

  .aliceBodyChatPanel {
    min-height: 120px;
    padding-top: 10px;

    .chatMessage {
      display: flex;
      align-items: flex-start;
      margin-bottom: 10px;
    }

    .userMessage {
      justify-content: flex-end;
      margin-right: auto;

      // border-radius: 0px 20px 20px 20px !important;
    }

    .userMessage .messageBubble {
      border-radius: 20px 0px 20px 20px !important;
      background-color: #7690c1;
      color: #fff;
      border-radius: 15px;
      padding: 10px 15px;
      font-size: 14px;
      font-family: sans-serif;
      max-width: 70%;
      word-break: break-word;
    }

    .aliceMessage {
      justify-content: flex-start;
    }

    .aliceMessage .messageBubble {
      border-radius: 20px 20px 20px 0px !important;
      // background: #3e82b4;
      background-color: #a7a5a5;
      color: #fff;
      border-radius: 15px;
      padding: 10px 15px;
      font-size: 14px;
      font-family: sans-serif;
      max-width: 70%;
      word-break: break-word;
    }
  }

  .footerContent {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    // position: absolute;
    left: 0;
    bottom: 0;

    .ovalInputContainer {
      display: flex;
      position: relative;
      align-items: center;
      width: 100%;
      flex-wrap: wrap;

      .ovalButton {
        right: 10px;
        border: none;
        color: #fff;
        outline: none;
        width: 109px;
        height: 38px;
        display: flex;
        font-size: 13px;
        font-weight: 800;
        margin-left: 10px;
        font-style: normal;
        text-align: center;
        position: absolute;
        padding: 10px 20px;
        line-height: normal;
        align-items: center;
        background: #406cb9;
        border-radius: 51.38px;
        font-family: sans-serif;
      }

      .disabledOvalButton {
        right: 10px;
        border: none;
        color: #fff;
        outline: none;
        width: 109px;
        height: 38px;
        display: flex;
        font-size: 13px;
        font-weight: 800;
        margin-left: 10px;
        font-style: normal;
        text-align: center;
        position: absolute;
        padding: 10px 20px;
        line-height: normal;
        align-items: center;
        background: #406cb9;
        border-radius: 51.38px;
        font-family: sans-serif;
        opacity: 0.5;
      }

      .ovalButtonMedia {
        right: 10px;
        border: none;
        color: #fff;
        outline: none;
        width: 109px;
        height: 38px;
        display: flex;
        font-size: 13px;
        font-weight: 800;
        font-style: normal;
        text-align: center;
        // position: absolute;
        padding: 10px 20px;
        line-height: normal;
        align-items: center;
        background: #406cb9;
        border-radius: 51.38px;
        font-family: sans-serif;
        margin-top: 0.2em;
      }

      .disabledOvalButtonMedia {
        right: 10px;
        border: none;
        color: #fff;
        outline: none;
        width: 109px;
        height: 38px;
        display: flex;
        font-size: 13px;
        font-weight: 800;
        font-style: normal;
        text-align: center;
        // position: absolute;
        padding: 10px 20px;
        line-height: normal;
        align-items: center;
        background: #406cb9;
        border-radius: 51.38px;
        font-family: sans-serif;
        opacity: 0.5;
        margin-top: 0.2em;
      }

      .ovalInput {
        // width: 455px;
        width: 100%;
        height: 51px;
        outline: none;
        padding: 10px 20px;
        border-radius: 51.38px;
        border: 0.816px solid #c4c4c4;
      }

      .ovalInput::placeholder {
        color: #b0b0b0;
        font-size: 13px;
        font-weight: 400;
        font-style: normal;
        line-height: normal;
        font-family: sans-serif;
      }
    }
  }

  .AliceChatContainer {
    // overflow: auto;
    min-height: 200px;
  }
}
