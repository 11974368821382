.footerSection {
    height: 35px;
    flex-shrink: 0;
    margin-top: auto;
    padding: 7px 0px;
    background-color: #f5f5f5;
    text-align: center !important;
    border-top: 1px solid #31a2f9;

    p {
        margin: 0px;
    }

   
}
.footerHighlightText {
    color: #81c240;
    font-weight: bold;
}

.footerTrademarkText {
    color: #81c240;
    font-weight: bold;
}