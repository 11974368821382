.logsSection {
    height: 46rem;

    .downloadLogBtn {
        width: 100%;
        height: 5rem;
        margin: 2rem 0 2rem;
        border-radius: 5px;
        background: #205493;
        color: #FFF;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.24px;
        border: none;
    }

    .objectHistorySection {
        display: flex;
        flex-direction: column;
        margin: 1rem 0;
    }

    .labelText {
        color: #000;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.24px;
    }

    .logsSectionBtn {
        display: flex;
        justify-content: space-between;
        height: 5rem;
        border-radius: 5px;
        background: rgba(235, 235, 235, 0.42);
        align-items: center;
        border: none;
        margin-bottom: 2rem;
        width: 100%;
    }


    .logsSectionBtnText {
        color: #49443F;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.24px;

    }




}