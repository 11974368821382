// scss-lint:disable EmptyRule

.fsa-btn-group {

  & {
    @include fsa-space-between();
    display: inline-flex;
    white-space: nowrap;
  }

  &--block {
    display: flex;
  }

  &--inline {
    display: inline-flex;
  }

  @include breakpoint(S) {
    &--block\@s  { display: flex; }
    &--inline\@s { display: inline-flex; }
  }
  @include breakpoint(M) {
    &--block\@m  { display: flex; }
    &--inline\@m { display: inline-flex; }
  }
  @include breakpoint(L) {
    &--block\@l  { display: flex; }
    &--inline\@l { display: inline-flex; }
  }
  @include breakpoint(XL) {
    &--block\@xl  { display: flex; }
    &--inline\@xl { display: inline-flex; }
  }

  &__item {

    & {
      @include fsa-button;
      @include fsa-button(secondary);
      flex: auto;
    }

    & + & {
      margin-left: -1px;
    }

    // scss-lint:disable SelectorFormat
    .fsa-btn-group--small  & { @include fsa-button(small);  }
    .fsa-btn-group--medium & { @include fsa-button(medium); }
    .fsa-btn-group--large  & { @include fsa-button(large);  }

    @include breakpoint(S) {
      .fsa-btn-group--small\@s  & { @include fsa-button(small);  }
      .fsa-btn-group--medium\@s & { @include fsa-button(medium); }
      .fsa-btn-group--large\@s  & { @include fsa-button(large);  }
    }

    @include breakpoint(M) {
      .fsa-btn-group--small\@m  & { @include fsa-button(small);  }
      .fsa-btn-group--medium\@m & { @include fsa-button(medium); }
      .fsa-btn-group--large\@m  & { @include fsa-button(large);  }
    }

    @include breakpoint(L) {
      .fsa-btn-group--small\@l  & { @include fsa-button(small);  }
      .fsa-btn-group--medium\@l & { @include fsa-button(medium); }
      .fsa-btn-group--large\@l  & { @include fsa-button(large);  }
    }

    @include breakpoint(XL) {
      .fsa-btn-group--small\@xl  & { @include fsa-button(small);  }
      .fsa-btn-group--medium\@xl & { @include fsa-button(medium); }
      .fsa-btn-group--large\@xl  & { @include fsa-button(large);  }
    }

    &:focus:not([disabled]) {
      z-index: 1;
    }
    &:active:not([disabled]) {
      background-color: $color-fsa-tertiary;
      z-index: 1;
    }

    &:focus:not([disabled]) {}

    &:active:not([disabled]) {
      background-color: $button-primary-hover;
      color: $button-primary-text;
    }

    &:not(:first-child):not(:last-child) {
      border-radius: 0;
    }

    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:only-child {
      border-radius: $button-border-radius;
    }

    &--active {
      @include fsa-button(primary);
      background-color: $color-fsa-selection-bg;
      color: $button-secondary-text;
      .fsa-btn-group & {
        color: $button-secondary-text; // intentionall over-specific
      }
      &:hover:not([disabled]) {
        color: $button-primary-text;
      }
    }

  }

}
