.level-one-heading {
  position: absolute;
  top: -100px;
  height: 0px;
  color: red;
}


// navbar css
.navBarcontainer {
  display: flex;
  z-index: 4;
  font-family: "Poppins", sans-serif;
  width: fit-content;
}

.nav_main {
  width: 100%;
  padding: 20px;
}

.nav_sideBar {
  background: #fff;
  color: #686A76;
  width: 250px;
  box-shadow: 1px 1px 1px rgb(220, 218, 218);
}

.nav_active {
  background: #fff;
  color: #686A76;
  width: 90px;
  box-shadow: 1px 1px 1px rgb(220, 218, 218);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.nav_topSection {
  display: flex;
  align-items: center;
  padding: 30px 15px;
}

.nav_avatar {
  width: 55px;
  height: 55px;
}

.nav_avatarIcn {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.nav_logo {
  font-weight: 700;
  margin-bottom: 0;
  font-size: 1.1rem;
  margin-left: 10px;
  color: #212529;
}

.nav_bars {
  display: flex;
  font-size: 25px;
  margin-left: 50px;
  box-shadow: 2px 2px 2px rgb(220, 218, 218);
}

.nav_link {
  display: flex;
  justify-content: left;
  font-weight: 300;
  color: inherit;
  padding: 10px 15px;
  text-align: left;
  font-size: 14.4px;
  gap: 15px;
  transition: all 0.5s;
  text-decoration: none;
}

.nav_linkHover {
  color: #686A76;
}

.nav_link:hover,
.nav_linkHover:hover {
  background-color: red;
  color: #fff;
  transition: all 0.5s;
}

.nav_icon,
.nav_linkText {
  font-size: inherit;
  color: inherit;
}

.nav_selectedBtn {
  background-color: red;
  color: #fff;
}

.nav_linkActive {
  color: inherit;
  transition: all 0.5s;
  text-decoration: none;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  padding: 5px;
}

.nav_linkActive:hover {
  color: #fff;
}

.nav_selected {
  background-color: red;
  color: #fff;
  transition: all 0.5s;
}

.nav_deselected {
  color: #686A76;
}

.nav_linkTextActive {
  font-size: 10px;
  padding: 0;
  margin: 0%;
}

.nav_iconActive {
  padding-bottom: 0%;
  margin-bottom: 0%;
}

.nav_btn {
  background-color: #fff !important;
  border: 0ch !important;
}

.nav_dropdownBtn {
  display: flex !important;
  align-items: center !important;
  justify-content: space-evenly !important;
}

.nav_dropBtn {
  border: 0ch;
  display: flex;
  color: #fff;
  padding: 5px 10px;
  gap: 15px;
  transition: all 0.5s;
  text-decoration: none;
}

.nav_linkHover:focus {
  color: #fff !important;
}

.navLink {
  transition: all 0.3s;
  text-decoration: none;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  padding: 15px 0px;
  color: #8497B0 !important;
}

.navLink:hover {
  color: inherit;
  text-decoration: none !important;
}

.iconActive {
  padding-bottom: 0%;
  margin-bottom: 0%;
}

.linkTextActive {
  font-size: 10px;
  padding: 0;
  margin: 0%;
}

.selected {
  background-color: red;
  color: #fff !important;
  transition: all 0.5s;
}

.abcLink {
  color: inherit;
  transition: all 0.3s;
  text-decoration: none;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  padding: 5px;
}

.abcLink1 {
  color: #fff !important;
  background-color: #25a4db !important;
  transition: all 0.3s;
  text-decoration: none;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  padding: 15px 0px;
}

.Categories {
  background-color: inherit !important;
}

.base_icon {
  height: 25px;
}

.scan_base_icon {
  height: 25px;
  width: 25px;
}

.thinLine {
  border-top: 0.1px solid #fff;
}

// popup start
.dropdownList {
  width: max-content;
  position: absolute;
  box-sizing: border-box;
  list-style: none;
  z-index: 999;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  left: 90px;
  top: 26.7rem;

  a {
    text-decoration: none;
  }

  a:visited {
    color: #3b3f42;
  }

  a:hover {
    color: #212529;
    text-decoration: none;
  }

  li {
    height: 50px;
    padding: 0.5rem 2rem;
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: center;

  }

  li:hover {
    text-decoration: underline;
  }

  .selectedLink {
    background-color: #fff;
    color: #236430;
    border-bottom: 2px solid #236430;
  }
}