$box-bgcolor-default: $white;
$box-border-color: $color-fsa-tertiary-200;
$box-padding: $size-medium;
$box-radius: $button-border-radius;

@mixin fsa-boxBase($boxBase-breakpoint:'') {
  .fsa-box#{$boxBase-breakpoint} {
    border: 1px $box-border-color solid;
    background-color: $box-bgcolor-default;
    border-radius: 0;
    padding: $box-padding;
    transition: all .23s ease-in-out;
    > :first-child {
      margin-top: 0;
    }
    > :last-child {
      margin-bottom: 0;
    }
  }
}

@mixin fsa-box($box-breakpoint:'') {
  .fsa-box {

    // Background Color
    &--bg-0#{$box-breakpoint} { background-color: transparent; }
    &--bg-1#{$box-breakpoint} { background-color: $color-fsa-white; }
    &--bg-2#{$box-breakpoint} { background-color: $color-fsa-tertiary-100; }
    &--bg-3#{$box-breakpoint} { background-color: $color-fsa-tertiary-200; border-color: shade($box-border-color, 10%); }

    // Border Width
    &--bw-0#{$box-breakpoint} { border: none; }
    &--bw-1#{$box-breakpoint} { border: 1px $box-border-color solid; }
    &--bw-2#{$box-breakpoint} { border: 2px $box-border-color solid; }
    &--bw-3#{$box-breakpoint} { border: 4px $box-border-color solid; }

    // Background Radius
    &--br-0#{$box-breakpoint} { border-radius: 0; }
    &--br-1#{$box-breakpoint} { border-radius: $box-radius; }
    &--br-2#{$box-breakpoint} { border-radius: ($box-radius * 2); }
    &--br-3#{$box-breakpoint} { border-radius: ($box-radius * 4); }
  }
}

// BASE ---------------------------------------------------------------------
@include fsa-boxBase;

@include breakpoint(S) {
  @include fsa-boxBase(\@s);
}
@include breakpoint(M) {
  @include fsa-boxBase(\@m);
}
@include breakpoint(L) {
  @include fsa-boxBase(\@l);
}
@include breakpoint(XL) {
  @include fsa-boxBase(\@xl);
}

@include fsa-box();

@include breakpoint(S) {
  @include fsa-box(\@s);
}
@include breakpoint(M) {
  @include fsa-box(\@m);
}
@include breakpoint(L) {
  @include fsa-box(\@l);
}
@include breakpoint(XL) {
  @include fsa-box(\@xl);
}
