.mainDiv {
  height: 90vh;
  display: flex;
  flex-direction: column;
  width: 100%;
  // overflow-y: auto;
}
.userGuideDiv {
  text-align: center;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 80%;
  margin: 1rem 2rem 2rem 5rem;

  .borderDiv {
    width: 60vw;
    // height: 26vh;
    margin: 1rem 2rem 2rem 5rem;
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    text-align: center;
    padding: 10px;

    // z-index: -1;
  }
  .borderDiv:before {
    content: '';
    position: absolute;
    border: 10px dashed #ccc;
    top: -8px;
    bottom: -8px;
    left: -8px;
    right: -8px;
    z-index: -1;
  }

  .heading {
    color: #000;
    // font-style: normal;
    font-weight: 300;
    font-size: 5rem;
    line-height: normal;
    margin: 3rem 4rem;
  }
}

.imageDiv {
  margin: 2rem 1rem 3rem 2rem;
}

.uploadSectionDiv {
  display: flex;
  text-align: left;
  padding: 2rem 4rem 2rem 5rem;
}

.filesDiv {
  margin: 3rem 3rem 3rem 4rem;
}

.browseButton {
  width: 18rem;
  height: 3.5rem;
  cursor: pointer;
  border-radius: 5px;
  background-color: white;
  border: 1px solid #406cb9;
  color: #406cb9;
  text-align: center;
  font-size: 1.6rem;
  font-weight: 600;
}

.fileNameText {
  color: #406cb9;
  font-size: 1.9;
  font-weight: 600;
  margin: 0.5rem;
}

.fileSizeText {
  color: rgba(0, 0, 0, 0.5);
  font-family: Poppins;
  font-size: 1.9rem;
  font-weight: 400;
  span {
    color: #000;
  }
}

.uploadButton,
.disableButton {
  width: 20rem;
  height: 5rem;
  border: none;
  margin-top: 4rem;
  border-radius: 4px;
  background: #205493;
  text-align: center;
  color: #fff;
  font-size: 2.5rem;
  font-weight: 500;
  line-height: normal;
}

.disableButton {
  opacity: 0.5;
}
.successMessage {
  color: #3ab46d;
  font-size: 1.9rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.errorMessage {
  color: red;
  font-size: 1.9rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.checkIcon {
  width: 2.2rem;
  height: 2.2rem;
  margin-left: 0.8rem;
}
