.k-listview.with-border {
  border: 0px solid #fff !important;
}

.k-listview.with-border2 {
  border: 0px solid #fff !important;
}

.SuspenseQueueContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start; // if you want to fill rows left to right
  min-height: 60vh;
  padding-left: 1em;
}

.left-container {
  height: 50%;
  width: 41%; // is 50% of container width
  margin-right: 2%;
}

.right-container {
  height: 50%;
  width: 56%; // is 50% of container width
}

.half-containers {
  flex: 0 0 100%;
  max-height: 50%;
  min-height: 50%;
  max-width: 100%;
  padding: 0.5em;
  overflow: hidden;
}

.upload-btn {
  width: 6.5em;
}

.submit-processing-btn {
  width: 12em;
}

.k-panelbar-content .k-content {
  background-color: black;
}

.unprocessed-file-name {
  font-size: 14px;
  margin-bottom: 0;
  width: 100%;
  word-break: break-word;
}

.unprocessed-upload-date {
  margin-top: 1em;
  font-size: 12px;
  float: right;
}

.minimized {
  min-height: 0vh;
}

.filter-section-open {
  min-height: 30vh;
}

.unprocessed-section-open {
  min-height: 40vh;
}

.float-right {
  float: right;
}

.chevron {
  margin-top: 10px;
  bottom: 0;
  font-size: 20px;
}

.suspense-queue-icon {
  margin-right: 10px;
  font-size: 25px;
}

.no-list-data {
  font-size: 14;
  margin-top: 3em;
  text-align: center;
}

.thumbnail {
  height: 100%;
  border: solid 1px rgb(188, 185, 185) !important;
}

.unprocessed-upload-date {
  font-size: 10px;
}

.unprocessed-file-name {
  .css-2b097c-container {
    min-width: 150px !important;
  }
}
.css-26l3qy-menu {
  width: 350px !important;
}
.selectSection {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.customHeightUploadedQueue {
  height: 120px !important;
}
.text-trim {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
