.icon-holder {
    width: 2.6rem;
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    div {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 100%;
      svg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        overflow: visible;
      }
    }
  }
  .innerCircle{
    max-height: 100px;
    max-width: 100px;
    border-radius: 50%;
    transform: scale(70%) translate(-0px,2px);
  }