// This overrides a lot of USWDS' _typography.scss, with other additions

p {
  line-height: inherit;
}

a {

  &           { color: $color-fsa-link; text-decoration-skip-ink: auto; }

  &:visited   { color: $color-fsa-link-visited; }

  &:hover     { color: $color-fsa-link-hover; }

  &[disabled] { pointer-events: none; cursor: not-allowed; } // hacky, and technically invalid HTML but does the intended job.

}

html,
button,
input,
select,
textarea {
  font-family: $font-sans; // redundant, but some browsers required this to be explicit
}

h1, .h1 { @include fsa-header('1'); }
h2, .h2 { @include fsa-header('2'); }
h3, .h3 { @include fsa-header('3'); }
h4, .h4 { @include fsa-header('4'); }
h5, .h5 { @include fsa-header('5'); }
h6, .h6 { @include fsa-header('6'); }

// scss-lint:disable SelectorFormat
.fsa-text--lead,
.fsa-font-lead,
.usa-font-lead {

  font-size: $font-size-4;
  line-height: $lead-line-height;
  max-width: 70ch;

  @include breakpoint(M) {
    font-weight: $font-light;
    font-size: $lead-font-size;
  }

  strong {
    font-weight: bolder;
  }

}
