////////////////////////////////////////////////////////////////////////
// Date Picker

$date-control-btn-width: 3.9rem; // from fsa-btn
$date-control-btn-height: $date-control-btn-width;
$date-control-btn-small-width: 3rem; // from fsa-btn
$date-control-btn-small-height: $date-control-btn-small-width;
$date-calendar-text-color-default: $color-fsa-base;
$date-calendar-text-color-disabled: tint($date-calendar-text-color-default, 60%);
$date-calendar-border-color: $form-outline-color;
$date-calendar-table-cell-border-color: tint($table-border-color, 50%);
$date-calendar-table-cell-padding: $size-default;
$date-calendar-bg-color: #fff;
$date-calendar-bg-color-disabled: tint($color-fsa-tertiary, 96%);
$date-calendar-bg-color-hover: $color-fsa-aqua-100;
$date-calendar-bg-color-selected: $color-fsa-selection-bg;

$dateControl: fsa-date-control;
$dateCalendar: fsa-date-calendar;

.#{$dateControl} {

  & {

    color: $date-calendar-text-color-default;

    .fsa-input + & {
      margin-left: -$date-control-btn-width;
    }

    .fsa-input--small + & {
      margin-left: -$date-control-btn-small-width;
    }

    .fsa-input--block + &,
    .fsa-field__item + & {
      margin-left: initial;
    }

    .fsa-input--block + &,
    .fsa-field__item + & {
      position: relative;
      display: block;
    }

  }

  &__date-input-hidden {
    display: none;
  }

  &__btn {

    @include reset-button();
    display: inline-block;
    vertical-align: baseline;
    width: $date-control-btn-width;
    height: $date-control-btn-height;
    overflow: hidden;
    color: transparent;
    text-indent: -300%;
    position: relative;
    top: -1px;
    vertical-align: middle;
    // background-image: url('#{$image-path}/calendar.png');
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUAgMAAADw5/WeAAAACVBMVEUAAABJRED///+d7TLCAAAAAXRSTlMAQObYZgAAAC9JREFUCNdjCGEMdRBlCAUBFDJq1apVS+HktMhUOAkSiUwFsSEkHpEoGAkRQTIfACPTLNlYz9hoAAAAAElFTkSuQmCC');
    background-repeat: no-repeat;
    background-position: center;

    &:hover:not(:disabled) {
      // background-image: url('#{$image-path}/calendar--blue.png');
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUAgMAAADw5/WeAAAACVBMVEUAAAAAcbz///8Qy/ePAAAAAXRSTlMAQObYZgAAAC9JREFUCNdjCGEMdRBlCAUBFDJq1apVS+HktMhUOAkSiUwFsSEkHpEoGAkRQTIfACPTLNlYz9hoAAAAAElFTkSuQmCC');
    }

    &--active {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUAgMAAADw5/WeAAAACVBMVEUAAAAAcbz///8Qy/ePAAAAAXRSTlMAQObYZgAAAC9JREFUCNdjCGEMdRBlCAUBFDJq1apVS+HktMhUOAkSiUwFsSEkHpEoGAkRQTIfACPTLNlYz9hoAAAAAElFTkSuQmCC');
    }

    &--date-is-valid {
      // background-color: rgb(46, 133, 64);
    }


    &[disabled] {
      opacity: .4;
    }

    .fsa-input--block + .#{$dateControl} &,
    .fsa-field__item + .#{$dateControl} & {
      position: absolute;
      top: -$date-control-btn-height;
      right: 0;
    }

    .fsa-input--small + .#{$dateControl} &,
    .fsa-input--small + .#{$dateControl} & {
      width: $date-control-btn-small-width;
      height: $date-control-btn-small-height;
    }

    .fsa-input--block.fsa-input--small + .#{$dateControl} &,
    .fsa-field__item.fsa-input--small + .#{$dateControl} & {
      top: -$date-control-btn-small-height;
      right: 0;
    }

  }

  &__text {
    visibility: hidden;
  }

}

.#{$dateCalendar} {

  @mixin date-calendar-disabled-item($opacity: .6) {
    cursor: text;
    opacity: $opacity !important;
    filter: grayscale(1);
  }

  @mixin date-calendar-cell-default {
    color: $date-calendar-text-color-default;
    border: 1px solid $date-calendar-table-cell-border-color;
    border-left: 0;
    border-right: 0;
    line-height: 1;
    text-align: center;
    vertical-align: bottom;
    cursor: pointer;
    padding: $date-calendar-table-cell-padding;
    width: $size-large;
    &:last-child {
      padding: $date-calendar-table-cell-padding;
    }
  }

  // ----------------------------------------------------------------------------
  // ----------------------------------------------------------------------------
  // ----------------------------------------------------------------------------
  // Root Default

  & {
    color: $date-calendar-text-color-default;
    border: 1px solid $date-calendar-border-color;
    position: absolute;
    z-index: 9999;
    text-align: center;
    background: white;
    user-select: none;
    margin-left: $size-default;
    margin-right: $size-default;
  }

  // ----------------------------------------------------------------------------
  // ----------------------------------------------------------------------------
  // ----------------------------------------------------------------------------
  // Root Variations

  &--static {

    box-shadow: none;
    position: relative;
    z-index: 0;
    margin: 0;
    margin-top: $size-small;

    .fsa-field__date-input-hidden + & {
      margin-top: 0;
    }

    &.#{$dateCalendar}--focus {
      box-shadow: none;
    }

    .#{$dateControl}__date-input-hidden + & {
      margin-top: 0;
    }

  }

  &--disabled {
    @include date-calendar-disabled-item();
    background-color: transparent;
    &:hover *,
    &:hover {
      cursor: text;
    }
  }

  &--focus {
    box-shadow: 0 $size-base $size-small 0 rgba(0, 0, 0, .25);
  }

  &--drag-enabled {

  }

  &--weeks-displayed {

  }

  &--drag-enabled {
    cursor: move;
  }

  // ----------------------------------------------------------------------------
  // ----------------------------------------------------------------------------
  // ----------------------------------------------------------------------------
  // Header

  &__title {
    /* [month] [year] title */
    @include font-size(3);
    text-align: center;
  }

  &__title-month,
  &__title-year {
    display: inline-block;
    line-height: 1;
    margin: $size-small 0 0;
    color: $date-calendar-text-color-default;
    // cursor: text;
  }

  // ----------------------------------------------------------------------------
  // ----------------------------------------------------------------------------
  // ----------------------------------------------------------------------------
  // Month/Year Navigation

  &__prev-but,
  &__next-but,
  &__today-but {
    line-height: 1;
    display: block;
    font-weight: $font-normal;
    cursor: pointer;
    color: $color-fsa-link;
    margin: 0 0 $size-base;
    text-align: center;
    // text-transform: uppercase;
    vertical-align: middle;
  }

  &__prev-but,
  &__next-but {

    font-size: 36px;
    padding: 0 $size-default $size-base $size-default;

    &:hover {
      color: $color-fsa-link-hover;
    }

    &:active:not(.#{$dateCalendar}__disabled-item) {
      @include fsa-focus();
    }

    &.#{$dateCalendar}__disabled-item,
    .#{$dateCalendar}__disabled-item & {
      @include date-calendar-disabled-item();
    }

  }

  &__prev-but {

    &--month {}

    &--year {
      margin-left: $size-small;
    }

  }

  &__next-but {

    &--month {}

    &--year {
      margin-right: $size-small;
    }

  }

  &__today-but {

    @include font-size(3);
    padding: $size-default $size-default;

    &.#{$dateCalendar}__disabled-item {
      @include date-calendar-disabled-item(1);
    }

    &:hover {
      color: $color-fsa-link-hover;
    }

    &:active:not(.#{$dateCalendar}__disabled-item) {
      @include fsa-focus();
    }

    .#{$dateCalendar}__disabled-item & {
      @include date-calendar-disabled-item(1);
    }

  }

  &__prev-but:not(.#{$dateCalendar}__disabled-item):hover,
  &__next-but:not(.#{$dateCalendar}__disabled-item):hover,
  &__today-but:not(.#{$dateCalendar}__disabled-item):hover {
    box-shadow: 0 0 0 1px $color-fsa-tertiary-200 inset; // maybe try $button-secondary-border
  }

  // ----------------------------------------------------------------------------
  // ----------------------------------------------------------------------------
  // ----------------------------------------------------------------------------
  // Table

  &__table {
    @include font-size(2);
    table-layout: fixed;
    empty-cells: show;
    border-collapse: collapse;
    position: relative;
    margin: 0;
    padding: 0;
    text-align: center;
    z-index: 2;
    max-width: 100%;
    width: 100%;
    @include breakpoint(S) {
      width: auto;
    }
    .#{$dateCalendar}--static & {
      width: 100%;
    }
  }

  &__thead {
    th {
      padding: 0;
      text-align: center;
      color: inherit
    }
  }

  &__day-name {
    display: block;
    padding: $date-calendar-table-cell-padding;
    line-height: 1;
  }

  &__day {

    @include date-calendar-cell-default();

    &:focus { // focused via [tabindex]
      // prevents weird double-focus effect (focus state collision/redundancy)
      outline: 0;
    }

    &--header {

      /* The "mon tue wed thu fri sat sun" day header styles */
      @include font-size(1);
      border: 0;
      border-top: 1px solid $date-calendar-border-color;
      text-align: center;
      vertical-align: middle;
      cursor: help;
      text-transform: uppercase;
      padding: $size-small $size-base;

    }

    &--unused {
      /* TD cell that is _not_ used to display a day of the month */
      cursor: text !important;
    }

    &--today {
      /* The "todays date" cell style */
      font-weight: $font-bold;
    }

    &--highlight { /* The "highlight days" style */ }

    &--hover {

      & {
        z-index: 99999;
      }

      .#{$dateCalendar}--focus & {
        @include fsa-focus();
        background: $date-calendar-bg-color-hover;
        &:hover {
          outline: 0 !important;
        }
      }

      .#{$dateCalendar}--static & {}

      .#{$dateCalendar}--disabled & {
        outline: 0 !important;
        background-color: transparent !important;
      }

    }

    &--hover:not(.#{$dateCalendar}__day--selected-date) {
      .#{$dateCalendar}--static & {
        background-color: $date-calendar-bg-color-hover;
      }
    }

    &:active {
      .#{$dateCalendar}--focus & {
        outline: 3px solid $color-focus !important;
      }
    }

    &.#{$dateCalendar}__day--disabled:active {
      .#{$dateCalendar}--focus & {
        outline: 0 !important;
      }
    }

    &--selected-date {
      $bgc: $date-calendar-bg-color-selected;
      background-color: $bgc;
      // color: #fff;
      font-weight: $font-bold;
      &.#{$dateCalendar}__day--hover {
        background-color: $bgc;
      }
    }

    &--0 { /* Monday */ }
    &--1 { /* Tuesday */ }
    &--2 { /* Wednesday */ }
    &--3 { /* Thursday */ }
    &--4 { /* Friday */ }
    &--5 { /* Saturday */ }
    &--6 { /* Sunday */ }

    &--month-out { /* Used only when `fillGrid: true` */ }

    &--disabled,
    &--out-of-range,
    &--not-selectable {

      &,
      &:hover {
        color: $date-calendar-text-color-disabled !important;
        background-color: $date-calendar-bg-color-disabled !important;
        cursor: text;
      }

      &.#{$dateCalendar}__day--hover {
        outline-color: gray !important;
      }

    }

  }

  &__week-header {

    @include date-calendar-cell-default();
    @include font-size(0);
    background: transparent;
    cursor: text;
    border-left: 0;
    font-weight: $font-bold;
    background-color: transparent;
    box-shadow:
      -2px 0 0 0 tint($color-fsa-tertiary, 90%) inset,
      -4px 0 0 0 tint($color-fsa-tertiary, 95%) inset,
      -6px 0 0 0 tint($color-fsa-tertiary, 98%) inset
    ;
    border-right: 1px solid $date-calendar-border-color;

    .#{$dateCalendar}__thead & {
      vertical-align: middle;
      background-color: transparent;
      font-weight: normal;
      text-transform: uppercase;
      cursor: help;
    }

  }

  // ----------------------------------------------------------------------------
  // ----------------------------------------------------------------------------
  // ----------------------------------------------------------------------------
  // Foot

  &__status-bar {
    /* tfoot status bar */
    @include font-size(2);
    text-align: center;
    cursor: text;
    font-weight: normal;
    text-transform: uppercase;
    border: 0 none;
    padding: ($date-calendar-table-cell-padding / 2);
  }

  // ----------------------------------------------------------------------------
  // ----------------------------------------------------------------------------
  // ----------------------------------------------------------------------------
  // Unused / Placeholders / etc

  &__row {}

  &__row--highlight {}

  &__tfoot {}

  // ----------------------------------------------------------------------------
  // ----------------------------------------------------------------------------
  // ----------------------------------------------------------------------------
  // Other

  &-is-dragging { /* body */ }

}

