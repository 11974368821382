.inBasketTable {
    height: 89vh;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;
    padding-right: 20px;
}

.inBasketTableContent {
    margin-top: 2rem;
    margin-left: 1.8rem;

    .pageTitle {
        margin-top: 0px;
    }

    .searchSection {
        color: #494440;
        font-family: "Source Sans Pro", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
        font-weight: 700;
        font-size: 2rem;
        font-weight: 700;
        margin-bottom: 0.5em;
        margin-top: 1.5em;
    }

    .keywordSearchTitle {
        color: #2e2c2a;
        font-size: 16.603px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.253px;
        padding-bottom: 4px;
    }

    .searchOuterContainer {
        display: flex;
        justify-content: space-between;
    }
}

.tableInBasketTable {
    max-width: calc(100vw - 12rem);

    td {
        text-align: center !important;
    }
}

.errorStyle {
    font-size: 1.3rem;
    color: red;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}