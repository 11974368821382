.updateObjTransfer{
    .k-window-title {
        color: #484440;
        font-size: 27px;
        font-weight: 600;
        letter-spacing: 0.44px;
        padding-top: 0px
    }
    .k-dialog-titlebar {
        border-bottom: 1.5px solid #D9D9D9 !important;
        padding: 15px 10px;
    }
    // .k-window-actions{
    //     border: 3px solid #EB5757;
    //     border-radius: 50%;
    //     padding: 4px !important;
    // }
    // .k-button-icon{
    //     color: #EB5757 !important;
    // }
    .k-icon {
        font-size: 25px;
    }
}
.searchTransfer{
    border-right: 1.5px solid rgb(217, 217, 217);
    padding-left: 7px !important;
    padding-right: 18px !important;
}
.transferTable{
    padding-left: 19px !important;
    padding-right: 10px !important;
}

.transferHeading {
    margin-top: 0px;
    margin-bottom: 12px;
    color: #000;
    font-family: Poppins;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
}
.transferSearchHeading{
    margin-top: 0px;
    margin-bottom: 13px;
    color: #000;
    font-family: Poppins;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
}