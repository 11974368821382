.metadataModal {
  .modalHeader {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    .modalHeaderTitle {
      margin: 0;
      font-size: 24px;
      font-weight: 550;
      color: #484440;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.44px;
      font-family: sans-serif;
    }

    .modalHeaderIcon {
      cursor: pointer;
    }
  }

  .metadataModalBody {
    padding: 1.5rem 4rem;
    height: 30em;
    overflow-y: auto;

    .modalBodySection {
      color: var(--text-01-body, #212529);
      font-family: sans-serif;
      // font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.24px;
      padding: 0px 5px;
      z-index: -1 !important;
    }

    .inputDiv {
      display: inline-block;
      .labelDiv {
        display: flex;
        justify-content: space-between;

        .removeIcon {
          color: red;

          cursor: pointer;
        }
      }

      .inputFieldSection {
        display: flex;
        font-size: 15px;
        margin: 1rem 0rem;
        gap: 2rem;

        .inputFieldInternalDiv {
          display: flex;
          flex-direction: column;
        }

        label {
          display: flex;
          margin-bottom: 0.2rem !important;
        }

        input {
          // display: block;
          // width: 100%;
          height: 32px;
          border: 1px solid #ced4da;
          border-radius: 4px;
        }

        select {
          display: block;
          width: 100%;
          height: 32px;
          background-color: #fff;
          font-weight: 400;
          border: 1px solid #ced4da;
          border-radius: 4px;
        }

        .dropdownStyle {
          border-radius: 5px;
          background-color: #fff;
          padding: 0 3px !important;
          border: 1px solid #ced4da;
          height: 32px;
        }
      }
    }
  }
}

.metadataFooter {
  // padding: 0px !important;

  .footerInternalDiv {
    width: 100%;
    height: 53px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .metadataBtn {
    background: white;
    color: #1f5492;
    border: 1px solid #1f5492;
    font-weight: 500 !important;
    border-radius: 4px;
    margin: 1rem;
    padding: 0.8rem 3rem 0.8rem 3rem;
    margin: 4rem 1rem 4rem 1rem;
  }

  .clearBtn {
    background: #1f5492;
    color: white;
    border: 1px solid #1f5492;
    border-radius: 4px;
    padding: 0.8rem 3rem 0.8rem 3rem;
    margin: 5rem 1rem 5rem 1rem;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
