.file-display-container{
    overflow-y:auto;
    min-height: 30vh;
    max-height: 30vh;

}

.file-status-bar{
    padding-left: 10px;
    width: 100%;
    vertical-align:top;
    position: relative;
    line-height: 50px;
    height: 60px;
    border: solid;
    border-width: 1px;
    border-color: lightgrey;
}


.file-type {
    display: inline-block!important;
    font-size: 12px;
    font-weight: 700;
    line-height: 13px;
    margin-right: 1em;
    padding: 0 4px;
    border-radius: 2px;
    box-shadow: 1px 1px 2px #abc;
    color: #fff;
    background: #0080c8;
    text-transform: uppercase;
}

.file-name {
    vertical-align:top;
    margin-left: 50px;
    color: black;
    cursor: pointer;
}

.file-error {
    margin-bottom: 20px;

    margin-left: 50px;
    color: #9aa9bb;
}

.file-error-message {
    color: red;
}

.file-type-logo {
    width: 50px;
    height: 50px;
    background-size: 100%;
    position: absolute;
}

.file-size {
    display:inline-block;
    vertical-align:top;
    color:#30693D;
    margin-left:10px;
    margin-right:5px;
    margin-left: 10px;
    color: #444242;
    font-weight: 700;
    font-size: 14px;
}

.file-remove  {
    position: absolute;
    top: 20px;
    right: 2em;
    line-height: 15px;
    cursor: pointer;
    color: red;
    margin-right: -10px;
}

.save-object-btn {
    float: right;
    min-width: 100px;
    padding: 10px;
}

.modal{
    z-index: 999;
    display: none;
    overflow: hidden;
}

.modal .overlay{
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,.66);
    position: absolute;
    top: 0;
    left: 0;
}

.modal .modal-image{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    overflow: hidden;
    object-fit: cover;
    width: 100%;
    height: 300px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.close {
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 35px;
    color: white;
    font-size: 30px;
    font-weight: bold;
    opacity: 100;
    
}