.formRow {
  display: flex;
  align-items: flex-end;
  .fsa-btn {
    margin-left: 0px;
  }
}

.fileCodePage {
  h2 {
    margin-left: 48px;
    font-weight: 600;
    margin-top: 0;
  }
}

.fc-grid-head-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fileCodePage {
  .k-window-title {
    margin-left: 48px !important;
    font-size: 24px !important;
    margin-top: 12px !important;
  }
}

.radio {
  min-width: 160px;
}

.k-radio-label {
  font-weight: 400 !important;
}

.fc-search-form {
  .k-textbox.k-state-invalid, input {
    border-color: lightgray !important;
  }

  .k-dropdown.k-state-invalid > .k-dropdown-wrap {
    border-color: lightgray !important;
  }

  display: flex;
  flex-direction: column;
  align-items: center;

  label {
    font-weight: 700;
  }

  .k-dropdown, .k-dropdowntree, .k-textbox {
    width: 32rem;
  }

  .formRow {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: 0 auto;
    margin-top: 6px;
    align-items: start;
  }

  .formRowThird {
    width: 90%;

    .form-field:not(:last-child) {
      margin-right: 12px;
    }

    .form-field {
      width: 33%;
    }

    .k-dropdown, .k-dropdowntree, .k-textbox {
      width: 100%;
    }
  }

  .formRowFull {
    width: 90%;
    margin: 0 auto;

    .form-field, .k-textbox, .k-textarea {
      width: 100%;
    }
  }

  .formRowHalf {
    .form-field {
      width: 48%;
    }

    .k-textbox, .k-textarea {
      width: 100%;
    }
  }

  .formRowQuarter {
    .radio {
      width: 20%;
      margin-left: 12px;
    }

    .k-textbox, .k-textarea {
      width: 100%;
    }

    .k-dropdown, .k-dropdowntree {
      width: 24rem;
      margin-right: 12px;
    }

    .fc-adv-field {
      margin-right: 12px;
    }
  }

  .formRow-b {
    .k-dropdown, .k-dropdowntree {
      width: 24rem;
      margin-right: 12px;
    }

    .adv-fc-comment {
      width: 50%;

      .k-textarea {
        width: 100%;
      }
    }
  }
}

.adv-fc-btn-row {
  justify-content: flex-end !important;

  .lite-btn {
    margin-left: 12px;

  }

  input {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    height: 36px !important;
    margin-top: 12px;
  }

  button {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    height: 36px !important;
    margin-top: 12px;
  }
}

.lite-btn {
  background: #fff !important;
  border: 1px solid #0068AD !important;
  color: #0068AD !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  height: 36px !important;
}

.fc-keyword-input {
  min-width: 28vw;
  margin-right: 12px !important;
}

.HeaderOne-StyleOne {
  margin-top: 0px;
}


.fc-view {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.k-radio:checked, .k-radio.k-checked {
  border-color: #205493 !important;
  color: #205493 !important;
  background-color: #205493 !important;
}

.k-button.k-primary {
  border-color: #205493 !important;
  color: #ffffff;
  background-color: #205493 !important;
  background-image: unset;
}

.lbl-row {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.required {
  font-size: 10px;
  font-style: italic;
}

.k-state-invalid {
  .k-dropdown-wrap {
    border-color: rgba(243, 23, 0, 0.5) !important;
  }
}

.css-2b097c-container {
  min-width: 180px;
}


.fsa-input, .fsa-textarea {
  height: 2.9rem;
}

.css-2b097c-container {
  min-width: 264px;
}

.create-dd {
  justify-content: flex-start !important;
}

.css-1wa3eu0-placeholder {
  color: hsl(0, 0%, 20%) !important;
}

.create-file-windows {
  position: relative;
  z-index: 1 !important;

  .close-button {
    position: absolute;
    right: 0;
    top: -10px;
    z-index: 2;
  }
}

.close-btn-self {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  color: grey;
  position: absolute;
  right: 10px;
  text-align: center;
  top: 5px;
}

.close-btn-self:hover {
  background-color: lightgray;
  color: grey;
}

.h3-div-2 {
  color: #494440;
  font-family: "Source Sans Pro", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
  font-weight: 700;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 0.5em;
  margin-top: 1.5em;
}

.fsa-field__label{
  color: #494440;
}

.k-window-title{
  margin-top: 0px !important;
}