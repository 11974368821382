////////////////////////////////////////////////////////////////////////
// Text Fields

.fsa-form-list {

  @include reset-ul();

  > * {

    @include reset-li();
    margin-top: .5em;

    > .fsa-form-list {
      margin-left: $size-medium;
    }

  }

}

// Mostly a straight copy of USWDS checkbox and radio elements,
// with a few adjustments

.fsa-checkbox,
.fsa-radio {

  // @include sr-only();
  position: absolute;
  opacity: 0;
  cursor: pointer;

  &__other {
    // margin-left: 1.7em;
    margin-top: .5em;
    max-height: 0;
    overflow: hidden;
    transition: all .46s ease-in-out;
    opacity: 0;
    visibility: hidden;

    .fsa-radio:checked ~ &,
    .fsa-checkbox:checked ~ & {
      visibility: visible;
      max-height: ($size-base * 38);
      opacity: 1;
    }
  }

  .lt-ie9 & {
    border: 0;
    float: left;
    margin: .4em .4em 0 0;
    position: static;
    width: auto;
    opacity: 1;
  }

}

.fsa-checkbox +  label,
.fsa-radio + label,
.fsa-checkbox + [type="hidden"] + label,
.fsa-radio + [type="hidden"] + label {

  $indent-amount: 1.7em;
  cursor: pointer;
  font-weight: $font-normal;
  text-indent: -$indent-amount;
  margin-left: $indent-amount;
  margin-right: $size-default;
  display: inline-block;
  position: relative;

}

.fsa-checkbox--solo +  label,
.fsa-radio--solo + label,
.fsa-checkbox--solo + [type="hidden"] + label,
.fsa-radio--solo + [type="hidden"] + label {
  $indent-amount: 1em;
  text-indent: -$indent-amount;
  margin-left: $indent-amount;
  margin-right: 0;
}

.fsa-radio--solo + label,
.fsa-radio--solo + [type="hidden"] + label {
  $indent-amount: 1.3em;
  text-indent: -$indent-amount;
  margin-left: $indent-amount + .05em;
}


.fsa-checkbox + :not(label),
.fsa-radio + :not(label) {
  &:before {
    display: block;
    background-color: $color-fsa-tertiary-200;
    padding: $size-default;
    margin-bottom: 1em;
    content: 'CSS ERROR: checkbox or radio requires a <label> element immediately following it. Refer to FSA Design System for guidance. ';
    color: orangered;
    border: 4px solid orangered;
    font-size: 2rem;
    font-weight: bold;
  }
}

.fsa-checkbox + label::before,
.fsa-radio + label::before,
.fsa-checkbox + [type="hidden"] + label::before,
.fsa-radio + [type="hidden"] + label::before {

  background: white;
  border-radius: $border-radius;
  box-shadow: 0 0 0 1px $form-checkbox-radio-outline-color;
  content: '\a0';
  display: inline-block;
  height: 1.8rem;
  line-height: .8;
  margin-right: .6em;
  text-indent: .15em;
  vertical-align: .2em;
  width: 1.8rem;
  margin-left: .1rem;

  .lt-ie9 & {
    display: none;
  }

}

.fsa-radio:not(.fsa-radio--solo) + label::before {
  margin-left: 2px; // warning: magic number ¯\_(ツ)_/¯
}

.fsa-checkbox--solo + label::before,
.fsa-radio--solo + label::before,
.fsa-checkbox--solo + [type="hidden"] + label::before,
.fsa-radio--solo + [type="hidden"] + label::before {
  margin-right: 0;
}

.fsa-checkbox + label::after,
.fsa-radio + label::after,
.fsa-checkbox + [type="hidden"] + label::after,
.fsa-radio + [type="hidden"] + label::after {
  content: '';
  position: absolute;
  left: -36px;
  top: -5px;
  bottom: -2px;
  width: 36px;
}

.fsa-checkbox--solo + label::after,
.fsa-checkbox--solo + [type="hidden"] + label::after{
  left: -18px;
  top: 0;
  bottom: 3px;
  width: auto;
  right: 0;
}

.fsa-radio--solo + label::after,
.fsa-radio--solo + [type="hidden"] + label::after {
  left: -25px;
  top: 0;
  bottom: 3px;
  width: auto;
  right: 2px;
}

.fsa-radio + label::before,
.fsa-radio + [type="hidden"] + label::before {
  box-shadow: 0 0 0 2px $color-white, 0 0 0 3px $form-checkbox-radio-outline-color;
  height: 1.6rem;
  width: 1.6rem;
  border-radius: 100%;
}

.fsa-checkbox:indeterminate + label::before,
.fsa-checkbox:checked + label::before,
.fsa-radio:checked + label::before,
.fsa-checkbox:indeterminate + [type="hidden"] + label::before,
.fsa-checkbox:checked + [type="hidden"] + label::before,
.fsa-radio:checked + [type="hidden"] + label::before {
  background-color: $form-checkbox-radio-fill;
  box-shadow: 0 0 0 1px $form-checkbox-radio-fill;
}

.fsa-radio:checked + label::before,
.fsa-radio:checked + [type="hidden"] + label::before {
  // scss-lint:disable all
  box-shadow:
    0 0 0 2px $color-white,
    0 0 0 4px $form-checkbox-radio-fill
  ;
}

.fsa-radio:focus + label::before,
.fsa-radio:focus + [type="hidden"] +  label::before {
  box-shadow:
    0 0 0 3px $color-white,
    0 0 0 6px $color-focus
  ;
}

.fsa-checkbox:checked + label::before,
.fsa-checkbox:checked + [type="hidden"] + label::before {
  background-image: url('#{$image-path}/correct8.svg');
  background-position: 50%;
  background-repeat: no-repeat;
}

.fsa-checkbox:indeterminate + label::before,
.fsa-checkbox:indeterminate + [type="hidden"] + label::before {
  background-image: url('#{$image-path}/dash.svg');
  background-position: 50%;
  background-repeat: no-repeat;
}

.fsa-checkbox:focus + label::before,
.fsa-checkbox:focus + [type="hidden"] + label::before {
  outline: 3px solid $color-focus;
  outline-offset: 4px;
}

.fsa-checkbox:disabled + label,
.fsa-radio:disabled + label,
.fsa-checkbox:disabled + [type="hidden"] + label,
.fsa-radio:disabled + [type="hidden"] + label {
  color: $form-text-color-disabled;
  cursor: not-allowed;
}

.fsa-checkbox:disabled + label::before,
.fsa-radio:disabled + label::before,
.fsa-checkbox:disabled + [type="hidden"] + label::before,
.fsa-radio:disabled + [type="hidden"] + label::before {
  background-color: $form-disabled-bg-color--check-radio;
  cursor: text;
  cursor: not-allowed;
  opacity: .5;
}

.fsa-radio:disabled + label::before,
.fsa-radio:disabled + [type="hidden"] + label::before {
  // scss-lint:disable all
  box-shadow:
    0 0 0 2px $form-disabled-bg-color--check-radio,
    0 0 0 3px $form-checkbox-radio-outline-color
  ;
}

.fsa-radio:disabled:checked + label::before,
.fsa-radio:disabled:checked + [type="hidden"] + label::before {
  background-color: $form-checkbox-radio-fill;
  // scss-lint:disable all
  box-shadow:
    0 0 0 2px #fff,
    0 0 0 4px $form-checkbox-radio-fill
  ;
  opacity: .3;
}

.fsa-checkbox:disabled:checked + label::before,
.fsa-checkbox:disabled:checked + [type="hidden"] + label::before {
  background-color: $form-checkbox-radio-fill;
  box-shadow: 0 0 0 1px $form-checkbox-radio-fill;
  opacity: .3;
}
