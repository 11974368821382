.file-display-container {
    min-height: 5px !important;
    margin-bottom: 5px;
}


.half-container {
    flex: 0 0 100%;
    max-height: 50%;
    min-height: 50%;
    max-width: 100%;
    padding-left: 2em;
    padding-right: 2em;
    overflow: hidden;
}