////////////////////////////////////////////////////////////////////////////////
// USWDS - Start off with a select set of imports from USWDS ///////////////////
@import 'uswds-select';
//@import 'core/DEVELOPMENT'; // optional
////////////////////////////////////////////////////////////////////////////////
// FSA - Custom ////////////////////////////////////////////////////////////////
// Core -------------- /////////////////////////////////////////////////////////
@import 'core/fsa.variables';
@import 'core/fsa.font';
@import 'core/fsa.utilities';
@import 'core/fsa.base';
@import 'core/fsa.grid';
// Elements -------------- /////////////////////////////////////////////////////
@import 'elements/fsa.skipnav';
@import 'elements/fsa.typography';
@import 'elements/fsa.list';
@import 'elements/fsa.buttons';
@import 'elements/fsa.figure';
@import 'elements/fsa.form-elements.textfields';
@import 'elements/fsa.form-elements.checkbox-radio';
@import 'elements/fsa.form-elements.switch';
@import 'elements/fsa.form-elements.select';
@import 'elements/fsa.slider';
@import 'elements/fsa.table';
@import 'elements/fsa.label';
@import 'elements/fsa.badge';
// Components -------------- ///////////////////////////////////////////////////
@import 'components/fsa.level';
@import 'components/fsa.search';
@import 'components/fsa.box';
@import 'components/fsa.disclaimer';
@import 'components/fsa.tophat';
@import 'components/fsa.header-app';
@import 'components/fsa.nav-global';
@import 'components/fsa.content-tabs';
@import 'components/fsa.modal';
@import 'components/fsa.alerts';
@import 'components/fsa.btn-group';
@import 'components/fsa.growl';
@import 'components/fsa.breadcrumb';
@import 'components/fsa.media-object';
@import 'components/fsa.forms';
@import 'components/fsa.forms.field';
@import 'components/fsa.date-picker';
@import 'components/fsa.footer-nav';
@import 'components/fsa.footer';
@import 'components/fsa.screen-id';
@import 'components/fsa.topper';
@import 'components/fsa.whiteout';
@import 'components/fsa.select-multi';
@import 'components/fsa.stepped-tabs';
@import 'components/fsa.stepped-control';
@import 'components/fsa.pagination';
@import 'components/fsa.spinbox';
@import 'components/fsa.progress';
@import 'components/fsa.splash';
@import 'components/fsa.timeline';
@import 'components/fsa.divider';
// Layout-------------- ////////////////////////////////////////////////////////
@import 'layout/fsa.section';
// Other -------------- ////////////////////////////////////////////////////////
@import 'elements/fsa.css-utilities';
// fin /////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
//#162E51 
.ds-footer__bd {
  max-width: 100vw;
  display: flex;

  img {
    width: 50px;
    margin-right: 20px;
  }
}

.k-pager-numbers .k-link.k-state-selected {
  color: black;
  background-color: #DEE5EF;
}

.k-link:hover {
  color: #0068AD;
  background-color: #DEE5EF;
}

.k-pager-numbers .k-link:hover {
  color: #0068AD;
  background-color: #DEE5EF;
}

.k-pager-numbers .k-link {
  color: #0068AD;
}

#main-content {
  display: flex;
  // margin: 0 12px;
  justify-content: space-between;
  min-height: 600px;
  margin-top: 24px;
}

.k-switch-on .k-switch-container {
  color: #ffffff;
  background-color: #236430;
}

.k-radio:checked,
.k-radio.k-checked {
  border-color: #236430;
  color: #ffffff;
  background-color: #236430;
}

.k-radio:checked:focus,
.k-radio.k-checked.k-state-focus {
  border-color: #236430;
  box-shadow: 0 0 0 2px rgba(35, 100, 48, 0.5);
}

.k-switch-on:hover .k-switch-container,
.k-switch-on.k-state-hover .k-switch-container {
  color: #ffffff;
  background-color: #0068AD;
}

.k-list-optionlabel.k-state-selected {
  color: #ffffff;
  background-color: #0068AD;
}

.k-list .k-item.k-state-selected {
  color: #ffffff;
  background-color: #0068AD;
}

.k-list .k-item:hover.k-state-selected,
.k-list .k-item.k-state-hover.k-state-selected,
.k-list-optionlabel:hover.k-state-selected,
.k-list-optionlabel.k-state-hover.k-state-selected {
  color: #ffffff;
  background-color: #0068AD;
}

// .page-title {
//     margin-left: 24px;
// }
.flexrow {
  display: flex;
  margin: 0 12px;
  justify-content: space-between;
}

.sidebar {
  width: 20%;
  max-width: 350px;
}

.main-section {
  width: 80%;
  margin-top: 2.4rem;
  height: max-content;
}

.page-title {
  margin-bottom: 2.4rem;
}

.page-header {
  margin-left: 24px;
}

.k-textbox.k-invalid,
.k-textbox.k-state-invalid,
.k-textbox.ng-invalid.ng-touched,
.k-textbox.ng-invalid.ng-dirty {
  border-color: lightgray;
  // rgba(35, 100, 48, 0.5)
}

.fsa-divider {
  width: 100%;
}

.guide {
  width: 30%;
  padding: 12px;
  // max-height: 35vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid gray;
  padding: 24px;
  border-radius: 4px;

  .num-list {
    width: 250px;
  }

  h2 {
    margin-top: 0;
  }
}

.fsa-logo {
  max-width: 60px;
}

.fsa-footer__bd {
  padding: 1rem
}

Label {
  font-size: 14px;
  line-height: 1.4285714286;
}

.fsa-footer__list {
  margin-bottom: 0;
}

.fsa-header-app__home-link:hover .fsa-header-app__app-abbr {
  /* border-bottom-color: #fff; */
  cursor: pointer;
}

.fsa-header-app__home-link:hover .fsa-header-app__app-abbr {
  /* border-bottom-color: #fff; */
  cursor: pointer;
}

.fsa-header-app__home-link:hover {
  text-decoration: none;
}

.advanced {
  display: flex;
  justify-content: center;
  // input[type="text"] {
  //     margin: 0 20px !important;
  // }
  align-items: flex-start;

  button {
    margin-top: 25px;
  }
}

.keyword {
  input {
    width: 400px;
    border: 0.5px solid #c8c7c6;
  }
}

.modal-link {
  text-decoration: underline;
  color: #0068AD;
  margin: 0;
}

.search-form {
  align-items: flex-start;
}

.meta-form {
  flex-direction: column;

  h1 {
    margin-left: 32px;
    margin-top: 0px;
  }
}

.index {
  align-items: flex-end;

  .form-field {
    margin-left: 24px;
  }
}


.searchBar {
  margin: 12px auto;
  //display: flex;
  justify-content: flex-start;
  align-items: baseline;
  //max-width: 80%;
  .flexrow {
    flex-wrap: wrap;

    .flexrow {
      justify-content: flex-start;
    }
  }

  .fsa-btn {
    padding: 0rem 2rem 0rem;
    font-weight: 500;
  }

  .advanced {
    justify-content: flex-start;
    margin: 0;
    margin-right: 12px;
  }
}

// .content {
//     min-height: 70vh;
//     max-height: 70vh;
// }
.content {
  min-height: 80vh;
  margin: 15px;
  width: calc(100vw - 125px) !important;  
  // margin-left: 3em;
  // margin-right: 3em;
}

// .footCon {
// }
.k-i-more-vertical::before {
  color: white;
}

.FilePlan {
  height: 62vh;
}

.simpleSearch,
.FilePlan {
  //margin: 60px 0;
  //height: 60vh;
  // margin-bottom: 200px;
  h1 {
    margin-left: 24px;
  }
}

.searchContent {
  h1,
  .columnSetBtnCon {
    margin: 0 24px;
  }
}

.columnSetBtnCon {
  display: none;
}

.k-textbox.k-invalid,
.k-textbox.k-state-invalid,
.k-textbox.ng-invalid.ng-touched,
.k-textbox.ng-invalid.ng-dirty {
  border-color: rgba(0, 243, 142, 0.212) !important;
}

.fileCode {
  .form-field {
    margin-bottom: 24px;
  }
}

.form-field {
  .fsa-field {
    margin-bottom: 0;
  }
}

.k-radio-list {
  display: flex;
  //border: 0.5px solid lightgray;
  border-radius: 4px;
  //padding: 0 12px;
  .k-radio-item {
    margin-right: 12px;
  }
}

hr {
  height: 1px;
  width: 100%;
  margin-left: 24px;
}

.metarow {
  margin-left: 38px;
  justify-content: left !important;
}

.specsDivs {
  display: flex;
  flex-direction: column;
  margin-right: 12px;
  //max-width: 200px;
  input {
    margin-top: 0px;
  }

  label {
  }
}

.specsDivs1 {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 7px 12px;
  // margin-right: 12px;
  //max-width: 200px;
  input {
    margin-top: 0px;
  }

  label {
  }
}

.inputRemoveIcon{
  color: #EB5757;
  cursor: pointer;
  font-size: 18px;
  font-weight: 700;
}

textarea {
  min-width: 120px;
}

.fPlanDivs {
  display: flex;
  flex-direction: row;
  min-width: 30px;
  min-height: 20px;
  padding: 5px;
  /* this */
}

.fPlanDivs > div {
  flex: 1 1 auto;
  background-color: lightgray;
  text-align: center;
  padding: 2px 10px;
  margin: 5px;
  /* and that, will result in a 10px gap */
}

.hide {
  visibility: hidden;
  height: 45vh;
}

// .k-grid-header col:nth-of-type(1) {
//     width: 10%
// }
.k-text-selection ::selection,
.k-block ::selection,
.k-panel ::selection,
.k-pane-wrapper ::selection,
.k-view ::selection,
.k-progressbar ::selection,
.k-numerictextbox ::selection,
.k-flatcolorpicker ::selection,
.k-combobox ::selection,
.k-datepicker ::selection,
.k-timepicker ::selection,
.k-datetimepicker ::selection,
.k-dateinput ::selection,
.k-dropdown ::selection,
.k-dropdowntree ::selection,
.k-multiselect ::selection,
.k-maskedtextbox ::selection,
.k-rating ::selection,
.k-window ::selection,
.k-drawer ::selection,
.k-notification ::selection,
.k-breadcrumb ::selection,
.k-pager-wrap ::selection,
.k-panelbar ::selection,
.k-card ::selection,
.k-splitter ::selection,
.k-spreadsheet ::selection,
.k-scheduler ::selection,
.k-chat ::selection,
.k-mediaplayer ::selection,
.k-pdf-viewer ::selection,
kendo-scrollview.k-scrollview-wrap ::selection,
kendo-scrollview.k-scrollview ::selection,
div.k-scrollview ::selection,
.k-chart ::selection,
.k-sparkline ::selection,
.k-stockchart ::selection {
  background-color: #236430;
  color: #ffffff;
}

.selectedNav {
  color: #25a4db;
  border-bottom: 2px solid #25a4db;
}

.fsa-nav-global__link:hover .fsa-nav-global__text,
.fsa-nav-global__link:focus .fsa-nav-global__text {
  box-shadow: unset;
}

.fsa-nav-global__link:hover .fsa-nav-global__text,
.fsa-nav-global__link:focus .fsa-nav-global__text {
  box-shadow: unset;
}

.k-dialog-titlebar {
  border-color: inherit;
  color: black !important;
  background-color: transparent !important;
  border: none !important;
}

.k-window-title {
  padding: .5em 0;
  margin: -.5em 0;
  font-size: 30px;
  font-weight: bold;
  line-height: 1.1111111111;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: default;
  flex: 1 1;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.create-con {
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .create-form {
    display: flex;
    flex-direction: column;
    background: #fff;
    padding: 12px 24px;
    border: 1px solid #DCDEE0;
    max-width: 1200px;

    .create-dd {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;

      .flex-item {
        margin-right: 12px;
      }

      .fsa-btn {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-left: 2rem;
        padding-right: 2rem;
        align-items: center;
        max-height: 30px;
      }

      .fsa-input,
      .fsa-textarea {
        height: 3.9rem;
      }
    }

    hr {
      height: 1px;
      width: 100%;
    }

    .rec-sched-con {
      .rec-sched {
        flex-wrap: wrap;
        max-width: 1000px;
        max-height: 300px;
        overflow-y: scroll;
        width: 100%;
      }
    }

    .flex-end-btn {
      justify-content: flex-end;
      align-items: flex-end;
    }
  }

  .aside-form {
    display: flex;
    flex-direction: row;
    border: 1px solid #DCDEE0;
    max-height: 190px;

    .flexrow {
      align-items: flex-end;
    }

    h3 {
      background: rgba(35, 100, 48, 0.15);
      margin-top: 0;
      padding: 24px;
    }

    .comp-evnt {
      padding: 0 24px;
    }

    // .comp-evnt-save {
    //     display: flex;
    //     flex-direction: row;
    //     justify-content: space-between;
    //     align-items: flex-end;
    //     margin-top: 12px;
    //     padding: 24px;
    // }
  }
}

.rs-btns {
  justify-content: flex-end;
}

.k-window {
  h1 {
    margin-top: 0;
    margin-bottom: 12px;
  }
  border-radius: 9px !important;
}


.k-grid-header .k-i-sort-asc-sm, .k-grid-header .k-i-sort-desc-sm, .k-grid-header .k-sort-order {
  color: white;
  text-align: start;
}

.fileCodeCon {
  width: 100%;
  align-items: flex-end;

  .fsa-field {
    margin-bottom: 0;
  }
}

.temp {
  .k-switch {
    width: 10em;
  }
}

.fc {
  .k-switch {
    width: 11em;
  }
}


.subnav {
  position: absolute;
  background: white;
  //margin-top: 52px;
  display: none;
}

.main-nav-item {
  margin-top: 0;
  margin-bottom: 0;
}

.fsa-nav-global__list {
  z-index: 1 !important;
}

.fsa-nav-global__list-item:hover {
  .subnav {
    display: block;
  }
}

.fsa-header-app__home-link:hover {
  text-decoration: none;
}

// 1201px and more — Extra large screens, TV.
@media (min-width: 1201px) {
  footer {
    // position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
  }
}

.k-animation-container {
  z-index: 10003;
  border-radius: 0 0 2px 2px;
  width: 100%;
}

// 1025px — 1200px: Desktops, large screens.
@media (max-width: 1200px) {
  .sidebar {
    width: 25%;
  }
  .main-section {
    width: 100%;
    flex-direction: column;
  }
  .guide {
    width: 100%;
    margin-bottom: 12px;
  }
}

// 769px — 1024px: Small screens, laptops.
@media (max-width: 1024px) {
}

// 481px — 768px: iPads, Tablets.
@media (max-width: 768px) {
  #main-content {
    flex-direction: column;
  }
  .sidebar {
    display: flex;
    flex-direction: column;
    max-width: unset;
    width: 100%;

    .authHeaders {
      display: flex;
      justify-content: space-between;

      div {
        width: 33%;
      }

      p {
        margin-left: 12px;
        margin-top: 24px;
      }
    }
  }
  .fsa-logo {
    width: 75px;
  }
}

// 320px — 480px: Mobile devices.
@media (max-width: 501px) {
  .authHeaders {
    flex-direction: column;

    hr {
      width: 100%;
      margin-top: 1em;
      margin-bottom: 1em;
    }
  }
  .sidebar .authHeaders div {
    width: 100%;
  }
  .fsa-logo {
    width: 50px;
  }
  .fsa-tophat__agency:after {
    content: '';
    margin-right: 0;
    visibility: hidden;
  }
  .fsa-tophat__secondary {
    .fsa-header-app__app-abbr {
      margin-left: 12px;
    }
  }
  .flexrow {
    margin: 0;
  }
  footer {
    display: block;
    position: unset;
  }
}

.k-textbox.k-invalid,
.k-textbox.k-state-invalid,
.k-textbox.ng-invalid.ng-touched,
.k-textbox.ng-invalid.ng-dirty {
  border-color: rgba(243, 23, 0, 0.5) !important;
}

.pageContent {
  min-height: 79%;
  display: flex;
  flex-direction: column;
}

[contentEditable=true]:focus:focus:not(:focus-visible), [href]:focus:focus:not(:focus-visible), [tabindex]:focus:focus:not(:focus-visible), button:not([disabled]):focus:focus:not(:focus-visible), iframe:focus:focus:not(:focus-visible), input:not([disabled]):focus:focus:not(:focus-visible), select:not([disabled]):focus:focus:not(:focus-visible), textarea:not([disabled]):focus:focus:not(:focus-visible) {
  outline: unset !important;
}

input:not([disabled]):focus:focus-visible, textarea:not([disabled]):focus:focus-visible {
  outline: 0px !important;
}


// .k-dialog-titlebar {

// }

.k-calendar-container {
  z-index: 19999 !important;
  width: auto;
}

// .k-window {

//     z-index: 1000!important;
// }

.footLogoCon {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  a {
    margin-top: 12px;
  }
}

.own-header {
  display: flex;
  justify-content: right;

  .own-btn-close {
    box-sizing: content-box;
    font-size: 2em;
    cursor: pointer;
    width: 1em;
    height: 1em;
    padding: 0.25em 0.25em;
    color: #000;
    border: 0;
    border-radius: 0.25rem;
    background-color: white;
    opacity: .8;
  }
}
