
.fsa-disclaimer {

  & {

    background-color: $color-fsa-primary-900;
    height: auto;
    padding-top: .5rem;
    padding-bottom: .5rem;
    font-size: 1.2rem;

    @include media($small-screen) {
      font-size: $h6-font-size;
    }

  }

  &__bd {

    display: flex;
    align-items: center;
    max-width: ($site-max-width + 24px);
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    color: $color-fsa-white;
    padding: 0 $size-default;

    @media print {
      max-width: none;
    }

  }

  &__official {
    margin-right: auto;
  }

  &__agency {
  }

  &__flag-icon {
    float: left;
    margin-right: .7rem;
    margin-top: 2px;
    width: 2rem;
  }

  &__stage {
    margin-left: $size-small;
    &--prototype {
      background-color: $color-fsa-yellow;
      color: black;
      padding: 0 $size-base;
      font-size: 10px;
      text-transform: uppercase;
    }
  }

}
