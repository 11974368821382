// Alert variables ---------- //
$usa-custom-alerts: () !default;
$usa-custom-alerts-bar: () !default;

$usa-alerts: (
  success: $color-green-lightest,
  warning: $color-gold-lightest,
  error: $color-secondary-lightest,
  info: $color-primary-alt-lightest
);

$usa-alerts-bar: (
  success: $color-green,
  warning: $color-gold,
  error: $color-secondary,
  info: $color-primary-alt
);

$alerts: map-merge($usa-alerts, $usa-custom-alerts);
$alerts-bar: map-merge($usa-alerts-bar, $usa-custom-alerts-bar);

$h-padding: $spacing-medium;
$bar-size: $spacing-small;
$left-padding: $h-padding + $bar-size;
$icon-size: 3rem;

.usa-alert {
  background-color: $color-gray-lightest;
  background-position: $h-padding $h-padding;
  background-repeat: no-repeat;
  background-size: 5.2rem; // TODO abrituary number based on svg size maybe?
  padding-bottom: $h-padding * 0.7;
  padding-left: $left-padding;
  padding-right: $h-padding;
  padding-top: $h-padding;
  position: relative;

  &::before {
    background-color: darken($color-gray-lightest, 40%);
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: $bar-size;
  }

  a {
    color: $color-primary-darker;

    &:focus,
    &:hover {
      color: $color-primary-darkest;
    }
  }

  ul {
    margin-bottom: 0;
    margin-top: $spacing-small;
    padding-left: $spacing-small;
  }
}

.usa-alert-slim {
  background-position: $h-padding $spacing-small;
  padding-bottom: $spacing-small;
  padding-top: $spacing-small;

  .usa-alert-text:only-child {
    margin-bottom: $spacing-x-small;
    padding-top: $spacing-x-small;
  }
}

.usa-alert-icon {
  display: table-cell;
  padding-right: $spacing-small;
}

.usa-alert-body {
  display: table-cell;
  vertical-align: top;
}

.usa-alert-heading {
  margin-top: 0;
  margin-bottom: 0;
}

.usa-alert-text {
  font-family: $font-sans;
  margin-bottom: 0;
  margin-top: 0;
}

.usa-alert-text:only-child {
  margin-bottom: $spacing-small;
  padding-top: $spacing-x-small;
}

@each $name, $bgcolor in $alerts {
  .usa-alert-#{$name} {
    background-color: $bgcolor;
    background-image: url('#{$image-path}/alerts/#{$name}.png');
    background-image: url('#{$image-path}/alerts/#{$name}.svg');

    &::before {
      background-color: map-get($alerts-bar, $name);
    }

    .usa-alert-body {
      padding-left: $icon-size + $h-padding;
    }
  }
}

.usa-alert-no_icon {
  background-image: none;
}

.usa-alert-paragraph {
  width: $text-max-width;
}

// Deprecated
.usa-alert {
  margin-top: 1.5em; // TODO deprecate
}
