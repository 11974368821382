.fsa-header-app {

  $component-bgcolor: $color-fsa-primary;
  $component-text-color: $color-fsa-white;
  $component-spacing: $size-small;

  & {
    background-color: $component-bgcolor;
    padding-top: $component-spacing;
    padding-bottom: ($component-spacing / 2);
    color: $component-text-color;
  }

  &__bd {

    display: flex;
    align-items: baseline;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    color: $component-text-color;
    // scss-lint:disable SpaceAfterPropertyColon TrailingSemicolon
    padding:
      ($component-spacing * 2)
      ($component-spacing + ($component-spacing / 2))
      ($component-spacing / 2)
    ;
    max-width: ($site-max-width + 24px);

    .fsa-header-app--fullscreen > & {
      max-width: none;
      @include breakpoint(L) {
        padding-left: ($component-spacing * 3);
        padding-right: ($component-spacing * 3);
      }
    }

    @include breakpoint(M) {
      // padding-top: ($component-spacing * 4);
      // padding-bottom: ($component-spacing);
    }

    @media print {
      max-width: none;
    }

  }

  &__primary {}

  &__secondary {

    font-size: 1.2rem;
    position: relative;
    display: flex;
    align-items: flex-end;
    text-align: right;
    margin-left: auto;
    padding-left: $component-spacing;
    padding-bottom: ($component-spacing / 4);

    @include breakpoint(M) {
      @include font-size(1);
    }

    @include breakpoint(L) {
      @include font-size(2);
    }

    a {
      &:link,
      &:visited,
      &:active {
        color: $component-text-color;
        text-decoration: none;
      }
      &:hover {
        text-decoration: underline;
      }
    }

  }

  &__profile {
    line-height: 1;
    display: flex;
  }

  &__profile-link {

    display: inline-block;
    white-space: nowrap;
    margin-left: $size-default;

    &:link,
    &:visited,
    &:active {
      color: $component-text-color;
      text-decoration: none;
    }

    &:hover {
      text-decoration: underline;
    }

    .fsa-header-app__profile-name + & {
      border-left: 1px solid white;
      padding-left: $size-default;
    }

  }

  &__profile-name {

    @include fsa-ellipsis();
    display: inline-block;
    max-width: ($size-base * 32);
    max-width: 18ch;

    @include breakpoint(S) {
      max-width: 40ch;
    }

    @include breakpoint(M) {
      max-width: 35ch;
    }

    @include breakpoint(L) {
      max-width: ($size-base * 55);
      max-width: 40ch;
    }

  }

  &__profile-auth {

  }

  &__profile-expand {

    $size: $size-default;

    display: inline-block;
    margin-left: ($size-base / 2);
    width: $size;
    height: ($size + .2rem);
    background-image: url('#{$image-path}/angle-arrow-down-hover.svg');
    background-size: 100%;
    background-repeat: no-repeat;

  }

  &__home-link {

    text-decoration: none;
    display: inline-block;
    color: $color-fsa-white;

    &:visited,
    &:active,
    &:hover {
      color: $color-fsa-white;
    }

    &:hover {
      // text-decoration: underline;
    }

  }

  &__app-name {

    display: flex;

    @include font-size(5);

    @include breakpoint(M) {
      font-size: $font-size-4;
    }

    @include breakpoint(L) {
      @include font-size(5);
    }

  }

  &__app-abbr {

    font-weight: $font-bold;
    border-bottom: 1px solid transparent;

    .fsa-header-app__home-link:hover & {
      border-bottom-color: $component-text-color;
      cursor: pointer;
    }

    &[title] {
      border-bottom: 1px solid transparent;
      text-decoration: none;
    }

    &:after {

      @include breakpoint(M) {
        content: '|';
        font-weight: $font-light;
        position: relative;
        top: -2px;
        padding-left: $size-base;
      }

      @include breakpoint(L) {
        padding-left: $size-small;
      }

      .fsa-header-app__home-link:hover & {
        border-bottom: 1px solid $component-bgcolor;
      }

    }

    &:only-child:after {
      display: none;
    }

  }

  &__app-full {

    display: none;
    font-weight: $font-light;

    &:only-child {
      @include fsa-ellipsis();
      display: block;
      max-width: $size-base * 39;
      font-size: 17px;
      font-weight: bold;
      @include breakpoint(S) {
        max-width: $size-base * 67;
      }
      @include breakpoint(M) {
        max-width: $size-base * 80;
        @include font-size(4);
        font-weight: $font-light;
      }
      @include breakpoint(L) {
        @include font-size(5);
        max-width: $size-base * 135;
      }
    }

    @include breakpoint(M) {
      display: inline;
      padding-left: $size-base;
    }

    @include breakpoint(L) {
      display: inline;
      padding-left: $size-small;
    }

    &:only-child {
      padding-left: initial;
    }

    .fsa-header-app__home-link:hover & {
      border-bottom: 1px solid $component-text-color;
    }

  }

}
