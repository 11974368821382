.updateObjectModalBody {

    .modalHeader {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;

        .headingText {
            color: #484440;
            font-family: Poppins;
            font-size: 22px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.44px;
            margin-left: 2rem;
        }

        .modalHeaderTitle {
            margin: 0;
            font-size: 24px;
            font-weight: 550;
            color: #484440;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.44px;
            font-family: sans-serif;
        }

        .modalHeaderIcon {
            cursor: pointer;

            .resizeIcon {
                margin-right: 1rem;
            }
        }
    }

    .searchPageModalBody {
        padding: 1rem;

        .center {
            margin: auto;
            width: 50%;
            text-align: center;
        }

        .updateObjFileCodeTitle {
            margin-left: 1.75em;
            font-size: 25px;
            font-weight: 600;
            margin-top: 0;
            margin-bottom: 0;
        }

        .updateObjFileContent {
            display: flex;
            // justify-content: flex-start;
            justify-content: space-between;
            // margin-right: 0;
            // max-height: 70vh;
            max-height: auto;


            .searchFileCode {
                padding-top: 0em;
                margin-left: 1em;
                display: flex;
                align-items: flex-start;
                max-height: 80%;

               

                .fileCodeHeadingText {
                    color: #000;
                    font-family: Poppins;
                    font-size: 17px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 18.346px;
                }

                a {
                    color: #0068ad !important;
                    text-decoration: underline !important;
                }
            }

            .fileCodeViewerSection {
                display: flex;
                flex-direction: column;
                width: 78%;
                border-left: 1px solid #D9D9D9;
                
                .fileCodeHeadingText {
                    color: #000;
                    font-family: Poppins;
                    font-size: 17px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 18.346px;
                    margin-left: 2rem;
                    margin-bottom: 2rem;
                }

                .fileCodeViewer {
                    display: flex;
                    margin-left: 1em;
                    align-items: flex-start;
                    // padding-top: 1em;
                    width: 97%;
                    max-height: 32em;
                    overflow-y: auto;
                    margin-bottom: 3rem;



                    .k-link:hover {
                        color: #494440 !important;
                        background-color: transparent !important;
                    }


                }

                .totalResultText {
                    display: flex;
                    justify-content: flex-end;
                    margin: 1rem 1rem 0;
                    border: 1px solid #E4E4E4;
                    height: 5rem;

                    .resultText {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-right: 1rem;
                        color: #656565;
                        text-align: right;
                        font-family: Poppins;
                        font-size: 1.5rem;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: 0.253px;
                    }
                }
            }



        }


        .tableDiv {
            display: flex;
            justify-content: center;
            font-family: 'Poppins';

            .table {
                border-collapse: separate;
                border-spacing: 18px;
            }

            .tableHeadDiv {
                background-color: #ebebeb;
                border-radius: 6px;
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px;
            }

            .tableHeadData {
                padding-left: 1.9rem;
                padding-right: 0.4rem;
                font-size: 1rem;
            }

            .tableBodyDiv {
                border: 1px solid black;
                border-top: none;
                margin: 0px;
            }

            .tableRow {
                margin-bottom: 3rem;
            }

            .tableRowData {
                color: rgba(73, 68, 63, 0.76);
                font-size: 1rem;
                border-radius: 10px;
                background: rgba(235, 235, 235, 0.42);
                padding: 1rem;
                cursor: pointer;

                .optionsImage {
                    display: none;
                }
            }
        }

        .clickedData {
            font-size: 1rem;
            border-radius: 10px;
            padding: 1rem;
            color: #fff;
            cursor: pointer;
            background-color: #25a4db;

            .optionClikedImage {
                padding-left: 12px;
                padding-top: 5px;
            }
        }

        .modalBodySection {
            color: var(--text-01-body, #212529);
            font-family: sans-serif;
            // font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.24px;
            padding: 0px 5px;
        }

        .inputFieldSection {
            // height: 80px;
            display: flex;
            font-size: 15px;
            margin: 1.5rem 0rem;
            gap: 2rem;

            .inputFieldInternalDiv {
                display: flex;
                flex-direction: column;
                width: 100%;
            }

            label {
                display: flex;
                margin-bottom: 0.2rem !important;
            }

            input {
                display: block;
                width: 100%;
                height: 32px;
                border: 1px solid #ced4da;
                border-radius: 4px;
            }

            select {
                display: block;
                width: 100%;
                height: 32px;
                background-color: #fff;
                font-weight: 400;
                border: 1px solid #ced4da;
                border-radius: 4px;
            }

            .radioBtnContainer {
                display: flex;
                align-items: center;
            }

            .dropdownStyle {
                border-radius: 5px;
                background-color: #fff;
                padding: 0 3px !important;
                border: 1px solid #ced4da;
                height: 32px;
            }

            .radioInputOuterDiv {
                display: flex;
                margin-top: 3rem;
                align-items: center;

                input {
                    width: 20px;
                    height: 20px;
                    margin: 0px 8px;
                    border-radius: 10px;
                    display: inline-grid;
                }
            }

            .radioInputOuterDiv input[type='radio'] {
                appearance: none;
                -webkit-appearance: none;
                width: 18px;
                height: 18px;
                margin: 0px 8px;
                border-radius: 10px;
                display: inline-grid;
                border: 3px solid #ced4da;
                background-color: white;
            }

            .radioInputOuterDiv input[type='radio']:checked {
                background-color: #1f5492;
            }
        }

        .searchFieldDiv {
            flex: 1 0 20%;
            height: 80px;
        }

        // Uploade image section
        .uploadImageSection {
            display: flex;
            flex-direction: column;
            width: 100%;

            .imageContents {
                input {
                    min-width: 10rem;
                    border: none;
                    height: auto;
                    padding: 0;
                }

                .iconInput {
                    display: flex;
                }

                .imageStyle {
                    width: 8rem;
                    height: 8rem;
                    border-radius: 50%;
                    background-size: cover;
                    background: white;
                    object-fit: contain;
                    border: 3px solid grey;
                    margin-top: -3rem;
                }
            }
        }

        .alertWarning {
            background-color: #fff1d2;
            padding-left: 2em;
            position: relative;
            margin-right: 1em;
            min-height: 3em;
            max-height: 20em;
            width: 35em;
            margin-left: auto;
            border-top: 2px solid;
            border-bottom: 2px solid;
            border-right: 2px solid;
            border-color: #FDB81E;
        }

        .alertWarning::before {
            background-color: #FDB81E;
            content: '';
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 10px;
            width: 1rem;
        }

    }

    .createRecordBtnStyle {
        background: white;
        color: #1F5492;
        border: 1px solid #1F5492;
        font-weight: 600 !important;
    }

    .searchPageFooter {
        padding: 0px !important;

        .footerInternalDiv {
            width: 100%;
            height: 53px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .saveRecordBtn {
            background: #215597 !important;
            color: white !important;
            border-color: transparent !important;
            border-radius: 3.38px;
        }

        .cancelRecordBtn {
            background: white;
            color: #1F5492;
            border: 1px solid #1F5492;
        }
    }
}