// scss-lint:disable PlaceholderInExtend

.fsa-alert {

  & {
    @extend .usa-alert;
    padding: 1em;
    margin-bottom: 1.5em;
    border: 1px solid rgba(0,0,0,.07);
    border-left: $size-base solid $color-fsa-tertiary-900;
    background-position: 1rem 2rem;
  }

  &::before {
    display: none;
  }

  // Variations ---------------------------------------------------------------

  &--success {
    @extend .usa-alert-success;
    background-color: $color-fsa-primary-100;
    border-left-color: $color-fsa-primary;
  }

  &--warning {
    @extend .usa-alert-warning;
    border-left-color: $color-fsa-yellow;
  }

  &--error {
    @extend .usa-alert-error;
    background-color: $color-fsa-red-100;
    border-left-color: $color-fsa-red;
  }

  &--info {
    @extend .usa-alert-info;
    background-color: $color-fsa-aqua-100;
    border-left-color: $color-fsa-aqua;
  }

  &--no-icon {
    @extend .usa-alert-no_icon;
    padding: $size-small;
  }

  // Elements

  &__close {

    @include fsa-button-reset;
    width: $size-extra-large;
    height: $size-extra-large;
    position: absolute;
    top: 0;
    right: 0;
    padding: ($size-default * 1.5);

    background-image: url('#{$image-path}/close.svg');
    background-position: center;
    background-repeat: no-repeat;

    &:active {
      opacity: .6;
    }

  }

  &__icon {
    @extend .usa-alert-icon;
  }

  &__body {

    @extend .usa-alert-body;

    .fsa-alert--no-icon & {
      padding: 0 $size-base;
    }

  }

  &__heading {

    @extend .usa-alert-heading;
    @include font-size(4);
    font-family: $font-sans;
    color: $color-base;
    font-weight: $font-bold;
    margin-bottom: .5em;

    .fsa-alert--no-icon & {
      @include font-size(3);
      margin-top: $size-base;
      margin-bottom: 0;
    }

  }

  &__text {
    @extend .usa-alert-text;
    &:only-child {
      margin-top: .5em;
    }
    & + & {
      margin-top: .5em;
    }
    .fsa-alert--no-icon & {
      margin: 0;
    }
    .fsa-alert--no-icon &:only-child {
      padding-top: 0;
    }
  }

}
