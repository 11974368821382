
.filecode-job-header {
    flex: 0 0 100%;
    max-height: 40%;
    min-height: 40%;
    max-width: 100%;
    padding-left:10px;
    padding-top:2px;
    padding-bottom: 1.5em;
    background-color: #12A5E4;
    border-bottom: solid;
    border-color: #DBDAD9;
    border-width: 2px;
    overflow: hidden;
}

.headerColumn {
    min-width:13.25em; 
    max-width: 13.25em; 
    margin-right: 1em;
}

.filecode-job-description {
    flex: 0 0 100%;
    padding-left: .5em;
    padding-top: 1em;
    padding-bottom: 1em;
    max-height: 60%;
    min-height: 60%;
    max-width: 100%;
    overflow: hidden;
    border-left:groove 1px #F0F0F0;
    border-right:groove 1px #F0F0F0;
    border-bottom:groove 1px #F0F0F0;

}
