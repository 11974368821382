.meta-modal-btn-con {
  display: flex;
  align-content: flex-end;
  justify-content: flex-end;
  align-items: flex-end;
  margin: 12px;

  .clear-btn {
    background: #fff;
    border: 1px solid #0068ad;
    color: #0068ad !important;
  }

  .fsa-btn:active:not([disabled]) {
    color: #0068ad !important;
    background-color: #fff !important;
    box-shadow: none;
    outline-offset: 3px;
  }

  .fsa-btn:hover:not([disabled]) {
    color: #0068ad !important;
    background-color: #fff !important;
  }
}

.meta-form {
  h2 {
    margin-top: 0px;
    margin-left: 34px;
  }
}

.meta-form-spacer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .k-dropdown {
    min-width: 300px;
  }

  .k-input {
    padding: 0 !important;
  }

  .defaultItem,
  .indexSheet {
    .k-input {
      font-style: italic;
    }
  }
}

.date-flexrow {
  flex-direction: row;
  display: flex;
  flex-wrap: nowrap;

  .flexcol {
    display: flex;
    flex-direction: column;
    margin-right: 12px;
  }

  label {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-top: 2px;
    margin-left: 1px;
    margin-right: 1px;
  }

  element.style {
  }

  .k-datepicker .k-picker-wrap,
  .k-timepicker .k-picker-wrap,
  .k-datetimepicker .k-picker-wrap,
  .k-dateinput .k-dateinput-wrap {
    border-color: rgba(0, 0, 0, 0.08);
    color: #656565;
    background-color: #ffffff;
  }

  .k-datepicker .k-picker-wrap,
  .k-timepicker .k-picker-wrap {
    padding: 4px;
  }
}

.alertBar-right {
  .fsa-growl-container {
    top: 20%;
  }
}

.modal-link {
  display: flex;

  span {
    margin-top: -5px;
    margin-left: 5px;
  }
}

.collapsed-params {
  display: flex;
  justify-content: flex-start;
  margin-left: 30px;
  flex-wrap: wrap;
  align-items: baseline;
  max-width: 55%;

  p {
    margin-top: 0;
    margin-bottom: 0.25em;
  }

  .collapsedKey {
    font-weight: 700;
  }

  span {
    margin-left: 6px;
  }
}

.param-pair {
  display: flex;
  justify-content: space-between;
  margin-left: 12px;
  font-size: 1.5rem;
}

.modal-link-con {
  display: flex;

  p {
    margin: 0;
    min-width: 148px;
  }
}

.param-pair {
  display: flex;
  justify-content: space-between;
  margin-left: 12px;
  font-size: 1.5rem;
}

.modal-link-con {
  display: flex;

  p {
    margin: 0;
    min-width: 148px;
  }
}

input {
  padding: 8px;
}

.keyword {
  max-width: 610px;
}

.underKeywordContainer {
  min-width: 60vw;
  margin-left: 0px !important;
  flex-wrap: nowrap !important;
}

.k-dropdown .k-dropdown-wrap,
.k-dropdowntree .k-dropdown-wrap {
  padding: 5px !important;
  background-color: #fff !important;
  border-color: #c8c7c6 !important;
  border-width: 0.5px !important;
  background-image: unset !important;
  border-radius: 0px !important;
  //border: 0.5px solid #c8c7c6;
}

.k-window-buttongroup.k-actions-stretched {
  border: unset !important;
}

.selectCell {
  display: inline-block;
}

.checkHeader {
  .k-checkbox {
    margin-left: 6px;
  }
}

.filterButton {
}

// .k-grid th, .k-grid td {
//     overflow: visible!important;
// }

.filterCon {
  width: 240px;
  height: 100px;
  position: fixed;
  z-index: 100;
  background: white;
  border: 2px solid gray;
  //display: none;
  margin-top: 148px;

  input {
    padding: 8px;
  }
}

.underKeywordContainer {
  min-width: 60vw;
  margin-left: 0px !important;
  flex-wrap: nowrap !important;
}

.k-dropdown .k-dropdown-wrap,
.k-dropdowntree .k-dropdown-wrap {
  padding: 5px !important;
  background-color: #fff !important;
  border-color: #c8c7c6 !important;
  border-width: 0.5px !important;
  background-image: unset !important;
  border-radius: 0px !important;
  //border: 0.5px solid #c8c7c6;
}

.k-window-buttongroup.k-actions-stretched {
  border: unset !important;
}

.selectCell {
  display: inline-block;
}

.checkHeader {
  .k-checkbox {
    margin-left: 6px;
  }
}

.filterButton {
}

// .k-grid th, .k-grid td {
//     overflow: visible!important;
// }

.k-grid-header .k-header > .k-link {
  align-items: left;
  min-height: 3em;
}

.k-grid-header th > .k-link > .k-icon {
  float: left;
  left: 2px;
  top: 11px;
  position: absolute;
  margin-right: 10;
}

.filterCon {
  width: 240px;
  height: 100px;
  position: fixed;
  z-index: 100;
  background: white;
  border: 2px solid gray;
  //display: none;
  margin-top: 148px;

  input {
    margin: 12px auto;
  }
}

.filter-hide {
  display: none;
}

.k-checkbox:checked {
  border-color: #205493 !important;
  color: #ffffff;
  background-color: #205493 !important;
}

.view {
  display: none;
}

.k-master-row:hover .view {
  display: inline-block;
  cursor: pointer;
  margin-left: 20px;
  font-size: 16px;
  font-weight: bold;
  color: #0068ad;
}

.customeFilterMenu {
  display: flex;
  flex-direction: column;
  padding: 0.25em;

  h4 {
    margin-top: 0 !important;
    margin-left: 6px;
  }

  .btn-row {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
  }
}

.k-grid-columnmenu-popup {
  //width: 300px!important;
  padding: 6px;
}

.title-row {
  display: flex;
}

.k-popup {
  background-color: #fff !important;
}

// .k-grid-header th>.k-link>.k-icon {
//     float: left;
// }

.k-header > .k-link > .k-icon.k-i-sort-asc-sm {
  margin-left: 0;
  margin-right: 12px;
}

.k-grid-content {
  min-height: 200px;
}

.param-pair {
  display: flex;
  justify-content: space-between;
  margin-left: 12px;
  font-size: 1.5rem;
}

.modal-link-con {
  display: flex;

  p {
    margin: 0;
    min-width: 148px;
  }
}

input {
  padding: 8px;
}

.underKeywordContainer {
  min-width: 60vw;
  margin-left: 0px !important;
  flex-wrap: nowrap !important;
}

.k-dropdown .k-dropdown-wrap,
.k-dropdowntree .k-dropdown-wrap {
  padding: 5px !important;
  background-color: #fff !important;
  border-color: #c8c7c6 !important;
  border-width: 0.5px !important;
  background-image: unset !important;
  border-radius: 0px !important;
  //border: 0.5px solid #c8c7c6;
}

.k-window-buttongroup.k-actions-stretched {
  border: unset !important;
}

.selectCell {
  display: inline-flex;
}

.checkHeader {
  .k-checkbox {
    margin-left: -4px;
    margin-right: 4px;
  }
}

/*.k-grid-header-wrap, .k-grid-footer-wrap {
  overflow: visible!important;
}
*/
.filterButton {
}

.k-grid th,
.k-grid td {
  overflow: visible !important;
}

.k-grid-header .k-header > .k-link {
  overflow: visible !important;
  align-items: center;
  height: auto;
  text-overflow: ellipsis;
  margin-left: 0;
  min-height: 2em;
  max-height: 2em;
}

.filterCon {
  width: 240px;
  height: 150px;
  position: absolute;
  z-index: 100;
  background: white;
  border: 2px solid gray;
  //display: none;
  top: 32px;
}

.k-checkbox:checked {
  border-color: #205493 !important;
  color: #ffffff;
  background-color: #205493 !important;
}

.view {
  display: none;
}

.k-master-row:hover .view {
  display: inline-block;
  cursor: pointer;
  margin-left: 20px;
  font-size: 16px;
  font-weight: bold;
  color: #0068ad;
}

.search-update-file-code {
  display: inline-block;
  cursor: pointer;
  margin-left: 1em;
  font-size: 16px;
  color: #1c83b4 !important;
}

.search-update-file-code-disabled {
  display: inline-block;
  cursor: default;
  margin-left: 1em;
  font-size: 16px;
}

.k-grid-content {
  min-height: 200px;
}

.grid-column-header {
  border: 0px;
  text-align: center;
  background-color: rgb(37, 164, 219);
  text-align: center !important;
  vertical-align: middle !important;
}

.k-grid-header th > .k-link > .k-icon {
  float: left;
  margin-right: 0;
}

.collapsibleAdvancedSearchContent {
  padding: 0 18px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  background-color: #f1f1f1;
  justify-content: space-between;
  margin-top: 12px;
}

.k-grid-columnmenu-popup {
  //width: 300px!important;
  padding: 6px;
}

.title-row {
  display: flex;
}

.k-popup {
  background-color: #fff !important;
}

// .k-grid-header th>.k-link>.k-icon {
//     float: left;
// }

.k-header > .k-link > .k-icon.k-i-sort-asc-sm {
  margin-left: 0;
  margin-right: 12px;
}

.k-grid-content {
  min-height: 200px;
}

.fullTextContainer {
  display: flex;
  align-items: center;
  margin: 0px 10px 0px 35px;
  padding-bottom: 0.25em;
}

.fullTextContainer > input {
  width: 19px !important;
  height: 19px;
}

.fullTextContainer > p {
  margin: 0 0 0 5px;
  font-weight: bold;
  font-size: 16px;
}

.keyWordSearchWrapper {
  display: flex;
}

.searchTable .k-grid tbody tr:hover td {
  background-color: #afccf3;

  .k-dropdown:active,
  .k-dropdowntree:active {
    box-shadow: #afccf3 !important;
  }
}

.search-dropdownlist-class .k-list .k-item:hover {
  background-color: #afccf3 !important;
}

.searchTable .k-grid tbody tr.highlightSearchTableRow td {
  background-color: #afccf3;
}

.searchTable .k-dropdown:focus-within,
.k-dropdowntree:focus-within {
  box-shadow: 0 0 0 3px #afccf3 !important;
}

.keyWordSearchWrapper {
  display: flex;
}

.searchTable .k-grid tbody tr:hover td {
  background-color: #afccf3;

  .k-dropdown:active,
  .k-dropdowntree:active {
    box-shadow: #afccf3 !important;
  }
}

.search-dropdownlist-class .k-list .k-item:hover {
  background-color: #afccf3 !important;
}

.searchTable .k-dropdown:focus-within,
.k-dropdowntree:focus-within {
  box-shadow: 0 0 0 3px #afccf3 !important;
}

.searchTableHeaderStyle {
  text-align: center;
  background-color: #25a4db;
  cursor: pointer;
  // margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  padding: 8px 0px;
}
.searchTableHeaderTitle {
  color: white;
  font-size: 16px;
  font-family: sans-serif;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.buowFilterParentContainer {
  position: relative;
  width: 400px;
}
.buowFilterDropdownDiv {
  width: 100%;
  cursor: pointer;
  background-color: white;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 3px;
}

.buowFilterOptions {
  width: 100%;
  border: none;
  background-color: white;
  cursor: pointer;
}
.filterOptionOverSelect {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.filterOptionsCheckboxesDiv {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  width: 100%;
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.buowFilterInputLabel {
  display: block;
  padding: 8px;
  cursor: pointer;
}
.checkboxContainer[type='checkbox'] {
  accent-color: #205493 !important;
}
