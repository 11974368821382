.progress-bar-container {
  height: 30px; /* Set the desired height for the vertical progress bar */
  width: 20px; /* Set the desired width for the vertical progress bar */
  background-color: #f0f0f0; /* Set the background color for the progress bar container */
  border-radius: 4px; /* Adjust as needed */
  display: flex;
  align-items: flex-end;
}

.progress-bars {
  height: 0; /* Start with 0 height */
  width: 100%; /* Set the desired width for the progress bar */
  // background-color: #aecf9e !important; /* Set the color for the progress bar */
  background-color: palevioletred;
  border-radius: 4px; /* Adjust as needed */
  transition: height 0.3s ease; /* Add a smooth transition effect when height changes */
}
.progressBarPercentage {
  position: absolute;
  font-size: 1.2rem;
  top: 50%;
  bottom: 50%;
  right: 14%;
}
.truncated-text-confidenceBar:hover {
  position: relative;
  z-index: 2;
}
