.breadcrumbOuterDiv {
    border-bottom: 1px solid #dbdad9;
}

.breadcrumbListStyle {
    margin: 0;
    padding: 7px;
    display: flex;
    list-style-type: none;
}

.breadcrumbListStyle li {
    margin: 0px 10px;
}

.breadcrumbListStyle li:last-child {
    margin-right: 0;
}

.breadcrumbListStyle a {
    color: #267292 !important;
    font-weight: 400;
    font-style: normal;
    line-height: normal;
    display: inline-block;
    letter-spacing: 0.26px;
    font-family: sans-serif;
    text-decoration-line: underline;
}

.breadcrumbListStyle span {
    margin: 0 5px;
    color: #267292 !important;
    font-weight: 400;
    font-style: normal;
    line-height: normal;
    display: inline-block;
    letter-spacing: 0.26px;
    font-family: sans-serif;
}

.disabledLinkStyle {
    text-decoration: none !important;
    pointer-events: none !important;
    cursor: default !important;
}