.center {
    margin: auto;
    width: 50%;
    text-align: center;
}

.updateObjFileCodeTitle {
    margin-left: 1.75em;
    font-size: 25px;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 0;
}

.updateObjFileContent {
    display: flex;
    justify-content: flex-start;
    // max-height: 60vh;
    //    overflow-y: auto;
    .searchFileCode {
        // padding-top: 1em;
        // margin-left: 4em;
        display: flex;
        align-items: flex-start;
        max-height: 80%;
        a {
            color: #0068ad !important;
            text-decoration: underline !important;
        }
    }

    .fileCodeViewer {
        display: flex;
        margin-left: 2em;
        align-items: flex-start;

        // padding-top: 1em;
        // width: 100%;
        .k-link:hover {
            color: #494440 !important;
            background-color: transparent !important;
        }

        max-height: 39em;
        overflow-y: auto;
    }
}

.resultBox {
    width: 97%;
    display: flex;
    border: 1px solid #E4E4E4;
    justify-content: flex-end;
    margin-top: 20px;
    padding: 15px;
    margin-left: 27px;
    color: #656565;
    font-size: 15px;
    font-weight: 400;
}

.k-tabstrip-top>.k-tabstrip-items .k-item.k-state-active {
    box-shadow: 0 -0.4rem 0 0 #205493 inset;
}

.k-link {
    font-size: 1.7rem;
}

.k-tabstrip-items .k-item {
    color: #494440 !important;
    border: none !important;
}

.k-tabstrip-top>.k-tabstrip-items .k-item:hover {
    box-shadow: 0 -0.4rem 0 0 #75716e inset;
}

.k-content {
    border: none !important;
}

.alert--warning {
    background-color: #fff1d2;
    padding-left: 2em;
    position: relative;
    margin-right: 1em;
    min-height: 3em;
    max-height: 20em;
    width: 13em;
    // margin-left: auto;
    border-top: 2px solid;
    border-bottom: 2px solid;
    border-right: 2px solid;
    border-color: #FDB81E;
}

.alert--warning::before {
    background-color: #FDB81E;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 10px;
    width: 1rem;
}

.fileCodeActions {
    position: absolute;
    bottom: 2em;
    right: 0;
}

.updateFileCode {
    .k-window-title {
        color: #484440;
        font-size: 27px;
        font-weight: 600;
        letter-spacing: 0.44px;
        padding-top: 0px
    }

    .k-dialog-titlebar {
        border-bottom: 1.5px solid #D9D9D9 !important;
        padding: 15px 10px;
    }

    // .k-window-actions{
    //     border: 3px solid #EB5757;
    //     border-radius: 50%;
    //     padding: 4px !important;
    // }
    // .k-button-icon{
    //     color: #EB5757 !important;
    // }
    .k-icon {
        font-size: 25px;
    }

    .k-window-content {
        overflow-x: hidden;
    }
}