.delete_modal_popup {
    .delete_modal_delete_icon {
        height: 11rem
    }

    .delete_modal_heading {
        font-size: larger;
        font-weight: 900;
    }
}

.modal_header_section {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    .modal_header_panel_text {
        margin: 0;
        font-size: 24px;
        font-weight: 550;
        color: #484440;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.44px;
        font-family: sans-serif;
    }

    .modal_header_panel_icon {
        cursor: pointer;
    }
}

.deleteContainer {
    .modal_dialog {
        max-width: 392px !important;
    }

    .modal_content {
        border-radius: 9px !important;
    }

    .flex_container_custom {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
    }

    .my_div {
        margin: 10px 5px;
    }

    .delete_modal_text_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .delete_modal_body_text {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .delete_modal_heading {
        color: #EB5757;
        font-size: 2.6rem;
        font-weight: 500;
        letter-spacing: 0.8px;
    }

    .delete_modal_text {
        color: rgba(0, 0, 0, 0.90);
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0.5px;
        margin: 5px 0px 10px;
    }
}

.modal_footer_section {
    height: 53px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;

    .modal_footer_delete_button {
        border: none;
        color: #fff;
        font-weight: 400;
        margin-right: 10px;
        border-radius: 5px;
        background-color: #eb5757;
    }

    .modal_footer_cancel_button {
        color: #1f5492;
        font-weight: 400;
        margin-left: 10px;
        border-radius: 5px;
        background-color: #fff;
        border: 1px solid #1f5492;
    }
}