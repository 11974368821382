.information-window {
  margin-top: -10%;
  margin-left: -15%;
  max-height: 290px;
  max-width: 95%;
  width: 95%;
  top: 50% !important;
  left: 18% !important;
  // right: 30% !important;

}

.information-body {
  margin: 15px auto 0px auto;
  position: relative;
  height: auto;
  width: 80%;
}
.information-window{
  .k-window-title{
    // width: 90%;
    // max-width: 90%;
    display: inline-block;
  }
  .k-window{
width: 100% !important;
  }
}