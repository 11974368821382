.fsa-tophat {

  $component-bgcolor: $color-fsa-primary-900;
  $component-text-color: $color-fsa-white;
  $component-spacing: $size-base; // .4rem / 4px

  & {
    background-color: #fff;
    color: #000;
  }

  &__bd {

    @include font-size(2);
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    color: #000;
    max-width: ($site-max-width + 24px);

    .fsa-tophat--fullscreen > & {

      max-width: none;

      @include breakpoint(L) {
        padding-left: ($component-spacing * 6);
        padding-right: ($component-spacing * 6);
      }

    }

    // scss-lint:disable TrailingSemicolon SpaceAfterPropertyColon
    padding:
      $component-spacing
      ($component-spacing * 3)
    ;

    @include breakpoint(M) {
      padding-top: ($component-spacing + ($component-spacing / 2));
      padding-bottom: ($component-spacing + ($component-spacing / 2));
    }

    @include breakpoint(M) {
      display: flex;
    }

    @include breakpoint(L) {
      @include font-size(3);
    }

    @media print {
      max-width: none;
    }

  }

  &__primary,
  &__secondary {
    display: inline-block;
  }

  &__primary {}

  &__secondary {
    text-align: right;
    // font-weight: $font-bold;
    margin-left: auto;
  }

  &__link {

    text-decoration: none;
    color: $component-text-color;

    &:visited,
    &:active,
    &:hover {
      color: $component-text-color;
    }

    &:hover {
      text-decoration: underline;
    }

  }

  &__agency {

    white-space: nowrap;

    &:after {

      content: '|';
      margin-right: $component-spacing;

      @include breakpoint(M) {
        display: none;
      }

    }

  }

  &__agency-logo {

    display: none;

    @include breakpoint(M) {
      display: inline-block;
      height: ($size-medium - .4rem);
      margin-right: $size-base;
      margin-top: -3px;
      vertical-align: middle;
    }

  }

  &__agency-abbr {

    margin-right: $component-spacing;
    text-decoration: none;

    @include breakpoint(M) {
      display: none;
    }

    .fsa-tophat__link & {
      cursor: pointer;
    }

    &[title] {
      border-bottom: 0;
    }

  }

  &__agency-fullname {

    display: none;

    @include breakpoint(M) {
      display: inline;
    }

  }

}
