.searchTable {
    th {
        background-color: #25a4db !important;
        vertical-align: unset !important;
        font-weight: bold !important;
        color: white !important;
        font-size: 16px !important;
        justify-content: center !important;
    }
}
.keyword{
    max-width: 610px;
}
