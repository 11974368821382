$label-spacing: ($size-base - .1rem);
$label-dismiss-width: 1.33em;

.fsa-label {

  & {
    @include reset-button();
    @include font-size(0);
    font-family: $font-sans; // brute-force to override any inheritance (e.g. within an <h1>)
    font-weight: $font-normal; // brute-force to override any inheritance (e.g. within an <h1>)
    line-height: 1;
    display: inline-block;
    background-color: $marker-bgc;
    color: $marker-text-color;
    text-transform: uppercase;
    text-decoration: none;
    line-height: 1;
    cursor: initial;
    padding: $label-spacing ($label-spacing + .1rem);
    letter-spacing: .5px;
    border-radius: 2px;
    box-shadow: 0 -1px 0 adjust-color($color-fsa-tertiary, $alpha: -0.75) inset;
    a &,
    button & {
      cursor: pointer;
    }
  }

  &:visited {
    color: $marker-text-color;
  }

  &--neutral {
    background-color: $marker-bgc-neutral;
    box-shadow:
      0 -1px 0   adjust-color($color-fsa-tertiary, $alpha: -0.70) inset,
      0 0 0 1px  adjust-color($color-fsa-tertiary, $alpha: -0.80) inset
    ;
  }

  &--general {
    background-color: $marker-bgc-general;
  }

  &--alert {

    background-color: $marker-bgc-alert;

    @media print {
      background-color: shade($marker-bgc-alert, 60%);
    }

  }

  &--warning {
    background-color: $marker-bgc-warning;
  }

  &--success {
    background-color: $marker-bgc-success;
  }

  &--large {
    @include font-size(3);
    padding-left: ($label-spacing * 1.5);
    padding-right: ($label-spacing * 1.5);
    letter-spacing: initial;
  }

  &--extra-large {
    @include font-size(4);
    padding-left: ($label-spacing * 2.25);
    padding-right: ($label-spacing * 2.25);
    letter-spacing: initial;
  }

  &--dismissable {
    position: relative;
    padding-right: ($label-dismiss-width);
  }

  &__remove {
    @include reset-button();
    width: $label-dismiss-width;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    background-image: url('#{$image-path}/close.svg');;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 48%;
    &[disabled] {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }

  &[disabled] {
    opacity: .75;
    filter: grayscale(55%);
    color: transparentize($marker-text-color, .1);
    cursor: not-allowed;
  }

  h1 &,
  h2 & {
    position: relative;
    top: -$size-base;
    margin-left: $size-base;
  }

}

// scss-lint:disable QualifyingElement
button.fsa-label,
a.fsa-label {

  &:hover:not([disabled]),
  &:focus,
  &:active {
    cursor: pointer;
    box-shadow:
      0 -1px 0   adjust-color($color-fsa-tertiary, $alpha: -0.10) inset,
      0 0 0 1px  adjust-color($color-fsa-tertiary, $alpha: -0.60) inset
    ;
  }

}
