.dashboardContent {
    display: flex;
    min-height: 79%;
}

.welcome {
    hr {
        margin-left: 0px;
    }
}

.guide-h2 {
    font-size: 3rem;
}

.guide-h4 {
    padding-top: 1.5rem;
    color: #494440;
    font-family: "Source Sans Pro", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
    font-weight: 700;
    font-size: 1.7rem;
    font-weight: 700;
}

// new dashboard css

.card1 {
    background-color: #dab22f;
}

.Route_Type_return1 {
    background-color: #2B374B !important;
    color: #fff !important;
}

.Route_Type_manual1 {
    background-color: #4472C4 !important;
    color: #fff !important;
}

.Route_Type_auto1 {
    background-color: #0D2764 !important;
    color: #8C887A !important;
}

// .arrow-right {
//     position: absolute;
//     right: 0;
//     top: 55px;
//     width: 0;
//     height: 0;
//     border-top: 30px solid transparent;
//     border-bottom: 30px solid transparent;
//     border-left: 30px solid green;
// }

// .arrow-left {
//     position: absolute;
//     left: 0;
//     top: 55px;
//     width: 0;
//     height: 0;
//     border-top: 30px solid transparent;
//     border-bottom: 30px solid transparent;
//     border-left: 30px solid green;
// }

.pointer {
    cursor: pointer;
}


.relative1 {
    // position: absolute;
    left: 70px;
}

.relative2 {
    // position: absolute;
    left: 120px;
}

.relative3 {
    // position:absolute;
    // margin-top: 5px;
    right: 110px
}

.relative4 {
    // position:absolute;
    right: 60px;
    // margin-top: 5px;
}

.relative5 {
    // position:absolute;
    right: 15px;
    // margin-bottom: 5px;
}

.relative6 {
    // position:absolute;
    right: 40px;
}

.relative7 {
    // position: absolute;
    right: 8px;
}

.relative8 {
    // position:absolute;
    left: 150px;
}

.dashboard-main {
    .dashboard-container {

        .card-outer {
            width: 450px;
            height: 480px;
            color: white;
            margin-left: 10px;
        }

        .card-header-bar-outer {
            padding-right: 10px;
            display: flex;
            justify-content: space-between;
            padding: 5px 18px;

            .pie-chartIcon {
                margin-top: 5px;
                padding-left: 3px;
                height: 25px
            }
        }

        .icon-default-size {
            height: 25px;
            cursor: pointer;
        }

        .icon-default-size2 {
            height: 15px;
            cursor: pointer;
        }
    }

    .title {
        color: #194d19;
        margin-left: 20px;
        display: block;
    }

    .accordion {
        .card {
            margin-left: 30px;
            margin-bottom: 10px;
            margin-top: 10px;
            // height: 100px;
            padding: 5px;
        }

        .title-text {
            color: #1d640d;
            margin-left: 15px;
        }

        .relative {
            position: absolute;
            right: 10px;
            height: 5px;
        }
    }
}

.k-chart-tooltip-wrapper {
    width: max-content !important;
    position: -ms-page !important;
}

// For cardsModal Start
.rowBoxModal {
    .boxModal {
        width: 12rem;
        height: 12rem;
        border-radius: 0.5rem;
        cursor: pointer;
        text-align: center;
        line-height: 12rem;
        border: 1px solid gray;
    }

    .selected-img-style {
        border: 4px solid green;
    }
}

.headerTitle {
    font-size: 2.2rem !important;
}

.modalBodyStyle {
    padding: 2rem !important;
}
.modalBody{
    padding: 1.5rem;
    display: flex;
    justify-content: center;
}

.dashboardUpdateBtn {
    font-size: 1.7rem !important;
    padding: 0.6rem 3rem !important;
}

.btnOuterDiv {
    margin-top: 4rem;
}

.plusIcon {
    border-radius: 50% !important;
    color: rgb(215, 149, 0);
}

.btn-outline-dark {
    color: #fff !important;
    border-color: #fff !important;
}