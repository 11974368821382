.objectMetadataContainer {
    .objectMetaInnerRow {
      width: 100%;
      margin-bottom: 2px;
      border-radius: 5px;
      padding: 10px 7px;
      background: rgba(235, 235, 235, 0.42);
      display: flex;
      justify-content: space-between;
    }
    .objectMetaInnerRowBtn {
      margin-top: 8px;
      display: flex;
      justify-content: end;
    }
  }