.fsa-content-tabs {

  $tab-padding: $size-small;

  & {

    @include overflow-gradient();

    @media (min-width: 75em) {
      background-image: none;
    }

    display: flex;
    margin-left: -$size-default;
    margin-right: -$size-default;
    align-items: center;
    box-shadow: 0 -1px 0 0 $color-fsa-tertiary-200 inset;

    @include breakpoint(L) {
      margin-left: 0;
      margin-right: 0;
    }

    @media print {
      background-color: transparent;
      background-image: none;
    }

  }

  & + & {
    margin: 1em 0;
  }

  &--light {
    @include overflow-gradient( $bgAll: #ffffff, $bgShadow: $color-fsa-tertiary-100 );
  }

  &--contrast {
    @include overflow-gradient( $bgAll: $color-fsa-tertiary-100 );
  }

  &--justified {}

  &__list {

    display: table;
    list-style: none;
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: $size-default;

    @include breakpoint(M) {
      width: auto;
    }

    @include breakpoint(L) {
      margin-left: 0;
    }

    // scss-lint:disable SelectorFormat
    .fsa-content-tabs--justified & {
      @include breakpoint(L) {
        width: 100%;
      }
    }

    .fsa-content-tabs--justified-equal & {
      @include breakpoint(L) {
        width: 100%;
        table-layout: fixed;
      }

    }

  }

  &__aside {

    display: block;
    flex: 1;
    text-align: right;

    // scss-lint:disable SelectorFormat
    .fsa-content-tabs--justified &,
    .fsa-content-tabs--justified-equal & {
      @include breakpoint(L) {
        display: none;
      }
    }

    @include breakpoint(L, down) {
      margin-right: $size-default;
    }

  }

  &__item {

    display: flex;
    display: table-cell;
    vertical-align: bottom;

  }

  &__label {

    @include font-size(2);
    @include reset-button();
    display: block;
    width: 100%;
    text-decoration: none;
    text-align: center;
    color: $color-fsa-tertiary;
    padding-right: $tab-padding;
    padding-left: $tab-padding;
    white-space: nowrap;

    &[type] {
      &:focus {
        box-shadow: none;
        // outline: none;
      }
    }

    @include breakpoint(L) {
      @include font-size(3);
    }

    @include breakpoint(XL) {
      white-space: normal;
      // padding-right: ($tab-padding * 2);
      // padding-left: ($tab-padding * 2);
    }

    .fsa-content-tabs__item:first-child & {
      padding-left: 0;
      @include breakpoint(L) {
        padding-left: 0;
      }
    }

    .fsa-content-tabs__item:last-child & {
      @include breakpoint(L) {
        padding-right: 0;
      }
    }

    &:link,
    &:visited {
      color: $color-fsa-tertiary;
    }

    &:focus {
      box-shadow: none;
      outline-offset: -3px;
    }

    &--small {
      @include font-size(2);
    }

    &--large {
      @include breakpoint(L) {
        @include font-size(4);
      }
    }

    // scss-lint:disable SelectorFormat
    .fsa-content-tabs--justified & {
      @include breakpoint(L) {
        padding-left: ($tab-padding / 2);
        padding-right: ($tab-padding / 2);
      }
    }

  }

  &__label-text {

    display: block;
    padding-top: $tab-padding;
    padding-bottom: ($tab-padding + $size-base);
    position: relative;

    @media print {
      color: $color-fsa-tertiary-300;
    }

    &:hover,
    &:focus {
      box-shadow: 0 (-$size-base / 2) 0 0 $color-fsa-tertiary-300 inset;
    }

    // scss-lint:disable SelectorFormat
    .fsa-content-tabs__label--active & {

      box-shadow: 0 (-$size-base) 0 0 $color-fsa-secondary inset;

      @media print {
        font-weight: $font-bold;
        color: black;
        box-shadow: none;
      }

    }

    .fsa-content-tabs__label--small & {
      padding-top: ($tab-padding - $size-base);
      padding-bottom: $tab-padding;
    }

    .fsa-content-tabs__label--unsaved &:after {
      content: '\2022';
      color: $color-fsa-red;
      font-size: 24px;
      line-height: 1;
      position: absolute;
      right: $size-small;
    }

    .fsa-content-tabs__label:hover & {
      box-shadow: 0 (-$size-base) 0 0 $color-fsa-tertiary-300 inset;
    }

    .fsa-content-tabs__label:focus & {
      box-shadow: none;
    }

  }

}