.fsa-footer-nav {

  & {
    padding-left: $size-default;
    padding-right: $size-default;
    background-color: tint($color-fsa-tertiary, 15);
  }

  &__bd {

    margin-left: auto;
    margin-right: auto;
    max-width: $site-max-width;
    text-align: left;
    color: white;
    padding: $size-default 0;

    @include breakpoint(L) {
      padding: 0;
    }
  }

  &__list {
    @include reset-ul();
  }

  &__list-item {

    @include reset-li();

    @include breakpoint(L) {
      display: inline-block;
    }

    &:not(:first-child) {
      @include breakpoint(L) {
        margin-left: $size-medium;
      }
    }

  }

  &__link {

    display: block;
    font-weight: $font-bold;
    padding: $size-default 0;
    text-decoration: none;
    color: $white;

    &:hover {
      color: $white;
      text-decoration: underline;
    }

  }

}
