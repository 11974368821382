.uploadPopup {
  display: none;
  position: fixed;
  bottom: 0;
  right: 17px;
  background-color: white;
  width: 500px;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  border-radius: 15px 15px 0px 0px;
  background: #fff;
  box-shadow: 0px 4px 44px 0px rgba(0, 0, 0, 0.25);
}

.uploadPopup.open {
  display: block;
}

.uploadHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f7f9fc;
  color: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  cursor: pointer;
}

.ploadTitle {
  display: flex;
  align-items: center;
}

.uploadSuccess {
  margin-left: 10px;
}

.collapseButton {
  background: none;
  border: none;
  // color: white;
  font-size: 20px;
  cursor: pointer;
  color: #000;
}

.closeButton {
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
}

.uploadBody {
  padding: 10px;
}

.uploadBody:collapsed {
  display: none;
}

.uploadedFilesText {
  flex-wrap: wrap;
  color: rgba(47, 44, 42, 0.67);
  font-family: Poppins;
  font-size: 15.667px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.253px;
  margin-left: 1rem;
}

.addButton {
  padding: 7px 7px;
  width: 126px;
  height: 36px;
}

.arrowIcon {
  margin-right: 1rem;
  margin-top: 0.2rem;
  color: #000;
}

.draggableSection {
  // height: 28rem;
  text-align: center;
}

.blurredDraggableSection {
  text-align: center;
  opacity: 0.6;
}

.fileText {
  color: #646363;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.4px;
}

.fileTypeText {
  color: #646363;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.2px;
}

.uploadBodyContainer {
  margin-bottom: 10px;
  height: 35rem;
  overflow-y: scroll;
  overflow-x: scroll;
}

.noBullets {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.uploadBodyList {
  border-radius: 3px;
  border: 0.5px solid #cfe5eb;
  background: #f7fbfc;
  margin: 4px 4px 8px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 2px;
}

.uploadBodyList1 {
  border-radius: 3px;
  border: 0.5px solid #eb5757;
  background: #f7fbfc;
  margin: 4px 4px 8px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 2px;
  background-color: #FFF2EB;
  cursor: pointer;
}

//horizontal bar start
.progressBarContainer {
  height: 7px;
  /* Set the desired height for the vertical progress bar */
  width: 95px;
  /* Set the desired width for the vertical progress bar */
  background-color: #f0f0f0;
  /* Set the background color for the progress bar container */
  border-radius: 4px;
  /* Adjust as needed */
  display: flex;
  align-items: flex-end;
}

.progressBars {
  height: 100%;
  /* Start with 0 height */
  width: 0;
  /* Set the desired width for the progress bar */
  // background-color: #aecf9e !important; /* Set the color for the progress bar */
  background-color: palevioletred;
  border-radius: 4px;
  /* Adjust as needed */
  transition: height 0.3s ease;
  /* Add a smooth transition effect when height changes */
}

.progressBarPercentage {
  position: absolute;
  font-size: 1.2rem;
  top: 50%;
  bottom: 50%;
  right: 14%;
}

.truncated-text-confidenceBar:hover {
  position: relative;
  z-index: 2;
}

//horizontal bar end

.checkIconOuter {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #6fcf97;
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
}

.icon {
  color: red;
}

.circleOuterStyle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #eb5757;
  border: 1.5px solid #eb5757;
  align-items: center;
  display: flex;
  justify-content: center;

}

.hide {
  visibility: hidden;
  display: none;
}

.circleOuterStyle1 {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #eb5757;
  border: 1.5px solid #eb5757;
  align-items: center;
  display: flex;
  justify-content: center;
  visibility: hidden;
}

.titleText {
  font-size: 13px;
  font-weight: 600;
  width: 30%;
}

.titleTexthide {
  font-size: 13px;
  font-weight: 600;
  width: 30%;
  color: red;
}

.titleValue {
  font-size: 13px;
  font-weight: 300;
}

.fileName {
  // display: flex;
  justify-content: flex-start;
  color: #74898e;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.26px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 13rem;
  text-align: start;

}



.fileName:focus {
  direction: rtl;

  padding-left: 10px;

  &:after {
    content: "\200E";
  }

}

.dropFileSection {
  text-align: center;

  .dropFileDragNDropPointer {
    cursor: pointer;
    padding: 2rem 0rem 13rem;
  }
}

.fileOuterWrapper {
  display: flex;
  border-radius: 3px;
  border: 2px dashed #868686;
  background: #fbfbfb;
  justify-content: center;
  align-items: center;
  padding: 2.6rem;
  margin: 1.5rem 0px;

  .fileOuterWrapperFlex {
    display: flex;
    justify-content: center;
    cursor: pointer;
    width: -webkit-fill-available;
  }
}

.uploadFileText {
  color: rgba(64, 64, 64, 0.3);
  font-family: Poppins;
  font-size: 21px;
  font-weight: 500;
  letter-spacing: 0.4px;
  cursor: pointer;
  margin-bottom: 0px !important;
}

.uploadFileTypeText {
  color: rgba(64, 64, 64, 0.3);
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.2px;
}

.spinnerOuterDiv {
  position: absolute;
  margin-left: 20rem;
  margin-top: 20rem;
  align-items: center;
}

.pbOne {
  margin-left: auto;
  margin-top: 10px;

}

.pbOnehide {
  margin-left: auto;
  margin-top: 10px;
  display: none;

}

.pbTwo {
  margin-left: auto;
  margin-top: 10px;

}

.conditioned {

  margin-left: auto;
  margin-top: 10px;

}

.pOne {
  display: flex;
  justify-content: flex-start;
}

.fileNamehide {
  color: #eb5757;
}

.titleTexthide {
  color: #eb5757;
}

.pOnehide {
  display: flex;
  justify-content: flex-start;
  display: none;
}

.status {
  margin-left: "15px";
  width: "12rem"
}


.statushide {
  margin-left: "15px";
  width: "12rem";
  display: none;
}