hr {
  margin-left: 0rem !important;
}

.basket_message_box {
  border: 0.063rem solid black;
  box-shadow: rgba(0, 0, 0, 0.16) 0rem 0.188rem 0.375rem, rgba(0, 0, 0, 0.23) 0rem 0.188rem 0.375rem;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.cursor {
  cursor: pointer;
}

.basket_suspense_box {
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.19) 0rem 0.625rem 1.25rem, rgba(0, 0, 0, 0.23) 0rem 0.375rem 0.375rem;
  background: white;

  .box_title {
    margin-bottom: -0.063rem;
    background-color: darksalmon;
    color: #214e21;

    @media screen and (min-width: 25px) {
      font-size: 0.75rem;
    }

    @media screen and (min-width: 768px) {
      font-size: 1.3rem;
    }

    @media screen and (min-width: 1024px) {
      font-size: 1.5rem;
    }
  }

  .box_Id {
    background-color: darksalmon;
    color: #214e21;
    font-weight: 700;
    height: 1.8em;
  }

  .box_number {
    max-width: 55%;

    @media screen and (min-width: 25px) {
      font-size: 0.35rem;
    }

    @media screen and (min-width: 625px) {
      font-size: 0.46rem;
    }

    @media screen and (min-width: 684px) {
      font-size: 0.57rem;
    }

    @media screen and (min-width: 768px) {
      font-size: 0.71rem;
    }

    @media screen and (min-width: 791px) {
      font-size: 0.76rem;
    }

    @media screen and (min-width: 866px) {
      font-size: 0.9rem;
    }

    @media screen and (min-width: 974px) {
      font-size: 0.95rem;
    }

    @media screen and (min-width: 1024px) {
      font-size: 1.16rem;
    }

    @media screen and (min-width: 1046px) {
      font-size: 1.2rem;
    }

    @media screen and (min-width: 1094px) {
      font-size: 1.3rem;
    }

    @media screen and (min-width: 1160px) {
      font-size: 1.4rem;
    }

    @media screen and (min-width: 1217px) {
      font-size: 1.5rem;
    }
  }

  .icon_box {
    display: flex;
    justify-content: space-between;
    background: white;
    padding: 0.188rem 0.625rem;
    position: absolute;
    height: 2.5rem !important;
    width: 40%;
    right: 0.8em;
    margin-top: 0.37em;
    align-items: center;
    max-width: 100px;
  }

  .date_style {
    font-weight: 700;
    padding: 0.25em;

    @media screen and (min-width: 25px) {
      font-size: 0.45rem;
    }

    @media screen and (min-width: 536px) {
      font-size: 0.44rem;
    }

    @media screen and (min-width: 768px) {
      font-size: 0.8rem;
    }

    @media screen and (min-width: 1024px) {
      font-size: 1.4rem;
    }
  }

  .location_icon {
    height: 2.188rem;
  }

  .top_icon {
    height: 1.55rem;
  }
}

.filter_style {
  font-size: 0.8em;
}

.header_style {
  font-size: 0.9em;
  color: #617850;
}

.header_style2 {
  color: #385623;
  font-weight: 600;
}

.default_color {
  color: #617850;
}

#button-tooltip {
  opacity: 1 !important;
}

#button-tooltip>.tooltip-inner {
  background-color: #fff;
  color: #000;
  border: 0.063rem solid #062e56;
  min-width: 31.25rem;
  max-width: 100%;
  z-index: 9999;
  opacity: 1 !important;
  padding: 0.625rem;
}

#button-tooltip>.tooltip-arrow {
  background-color: #fff;
  border-top: 0.313rem solid #062e56;
  z-index: 9999;
  opacity: 1 !important;
}

.hoverImageStyle {
  width: 15.625rem;
  border: 0.031rem solid rgb(133, 130, 130);
  margin: 0.313rem;
}

.arrow-left {
  transform: rotate(3.142rad);
  position: absolute;
  z-index: 1;
  top: 3.438rem;
  width: 0;
  height: 0;
  border-top: 2.188rem solid transparent;
  border-bottom: 2.188rem solid transparent;
  border-left: 2.188rem solid green;
}

.arrow-left-disabled {
  transform: rotate(3.142rad);
  position: absolute;
  z-index: 1;
  top: 3.438rem;
  width: 0;
  height: 0;
  border-top: 2.188rem solid transparent;
  border-bottom: 2.188rem solid transparent;
  border-left: 2.188rem solid rgb(222, 225, 222);
}

.arrow-right {
  position: absolute;
  top: 3.438rem;
  z-index: 1;
  right: 0;
  width: 0;
  height: 0;
  border-top: 2.188rem solid transparent;
  border-bottom: 2.188rem solid transparent;
  border-left: 2.188rem solid green;
}

.arrow-right-disabled {
  position: absolute;
  top: 3.438rem;
  z-index: 1;
  right: 0;
  width: 0;
  height: 0;
  border-top: 2.188rem solid transparent;
  border-bottom: 2.188rem solid transparent;
  border-left: 2.188rem solid rgb(222, 225, 222);
}

.Horizon-scrollView {
  overflow: hidden;
  white-space: nowrap;
  display: flex !important;
  flex-wrap: nowrap !important;
  // justify-content: space-around;
}

.Route_Type_return {
  background-color: #f4b081 !important;
  color: #fff !important;
}

.Route_Type_manual {
  background-color: #4472c4 !important;
  color: #fff !important;
}

.Route_Type_auto {
  background-color: #a8d08c !important;
  color: #8c887a !important;
}

.custom_width {
  flex: 0 0 18%;
  max-width: 18%;
  margin: 0rem 0.625rem 1.25rem;
}

.deleteBoxShadow {
  box-shadow: orange 0rem 0.25rem 1.25rem, orange 0rem 0.313rem 0.313rem, orange 0rem 0.563rem 0.313rem;
}

.undoButton {
  font-size: 0.75rem;
}

.hoverImageStyleblock {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hoverTextMsg {
  font-size: 14px;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contenterInbasketOuter {
  width: calc(100vw - 105px) !important;

}

.notes_IconStyle {

  @media screen and (min-width: 25px) {
    width: 1.45rem;
  }

  @media screen and (min-width: 536px) {
    width: 1.24rem;
  }

  @media screen and (min-width: 768px) {
    width: 1.8rem;
  }

  @media screen and (min-width: 1024px) {
    width: 2.5rem;
  }
}

.nodataFoundInbasket {
  height: 150px;
  align-items: center;
  display: flex;
  justify-content: center;
}
.nodataFoundMessage{
  height: 50px;
  align-items: center;
  display: flex;
  justify-content: center;
}
.defaultBuowHeight{
  height: 175px;
}