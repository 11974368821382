.glbRuleDefinitionModalStyle,
.glbDispositionModalStyle {
  .modal-content,
  .modal-dialog {
    border: none;
    width: 600px;
    border-radius: 9px;
  }
}

.glbMetadataModal {
  .modal-content {
    border-radius: 9px !important;
  }
}

.glbRuleDefinitionTable,
.glbRoutingStepsTable,
.glbOrchestrationTable,
.glbSystemConfigurationTable,
.glbBUOWDisplayNameTable,
.glbUsersTableTable,
.glbRolesTable,
.glbPermissionsTableTable,
.glbbuowTable,
.glbUserPreferenceTable,
.glbUserPreferenceTable,
.glbTableInBasketTable,
.glbTableUnitOfWork {
  .k-grid th {
    padding: 0px 0px 0px 0px !important;
    background-color: #236430;
    align-items: center;
    vertical-align: middle !important;
  }

  .k-grid td {
    // text-align: center !important;
    vertical-align: middle;
  }

  .k-grid-header .k-header > .k-link {
    padding: 5px 0px !important;
    border-right: 2px solid #fff;
  }

  .k-grid-header .k-header {
    border-left-width: 0;
    border-left: 0.5px solid #fff;
    border-right: 0.5px solid #fff;
  }
}

.glbSearchTable {
  .k-grid th {
    padding: 0px 0px 0px 0px !important;
    background-color: #12a5e4;
    align-items: center;
    vertical-align: middle !important;
  }

  .k-grid td {
    // text-align: center !important;
    vertical-align: middle;
  }

  .k-grid-header .k-header > .k-link {
    padding: 5px 0px !important;
    border-right: 2px solid #fff;
  }

  .k-grid-header .k-header {
    border-left-width: 0;
    border-left: 0.5px solid #fff;
    border-right: 0.5px solid #fff;
  }
}

.k-grid tr td {
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left !important;
}

//  Routing Steps , Users Table
.glbRoutingStepsModalStyle {
  .modal-dialog {
    max-width: 782px !important;
  }

  .modal-content {
    border-radius: 9px !important;
  }
}

.glbUsersTableModalStyle {
  .modal-dialog {
    max-width: 782px !important;
  }

  .modal-content {
    border-radius: 9px !important;
  }
  .k-switch-handle {
    width: 26px;
    height: 26px;
  }
  .k-switch-on .k-switch-handle {
    border: 2px solid #0075ff;
    background-color: white;
  }
  .k-switch-on:hover .k-switch-handle,
  .k-switch-on.k-state-hover .k-switch-handle {
    border: 2px solid #0075ff;
    background-color: white;
  }
  .k-switch-off .k-switch-handle {
    border: 2px solid #c7c7cc;
    background-color: white;
  }
  .k-switch-off:hover .k-switch-handle,
  .k-switch-off.k-state-hover .k-switch-handle {
    border: 2px solid #c7c7cc;
    background-color: white;
  }

  .k-switch-on .k-switch-container {
    color: #ffffff;
    background-color: #0075ff;
  }
  .k-switch-off .k-switch-container {
    color: #ffffff;
    background-color: #c7c7cc;
  }
  .k-switch-on:hover .k-switch-container,
  .k-switch-on.k-state-hover .k-switch-container {
    color: #ffffff;
    background-color: #0075ff;
  }
  .k-switch-off:hover .k-switch-container,
  .k-switch-off.k-state-hover .k-switch-container {
    color: #ffffff;
    background-color: #acacb2;
  }
}

.glbMakeItDefaultModalStyle {
  .modal-dialog {
    max-width: 430px !important;
  }

  .modal-content {
    border-radius: 9px !important;
  }
}

.glbBuowModalStyle {
  .modal-dialog {
    max-width: 650px !important;
  }

  .modal-content {
    border-radius: 9px !important;
  }
}

// Orchestration && user preference
.glbOrchestrationModalStyle,
.glbUserPreferenceModalStyle {
  .modal-content,
  .modal-dialog {
    border: none;
    width: 600px;
    border-radius: 9px;
  }
}

// Roles Table
.glbRolesTableModalStyle {
  .modal-dialog {
    max-width: 600px !important;
  }

  .modal-content {
    border-radius: 9px !important;
  }
}

// System Configuration , Permissions Table
.glbSystemConfigurationModalStyle,
.glbBUOWDisplayNameModalStyle,
.glbPermissionsTableModalStyle {
  .modal-content,
  .modal-dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    border: none;
    width: 600px;
    border-radius: 9px;
  }

  .k-switch-on .k-switch-handle {
    border: 2px solid #12b28c;
    background-color: white;
  }

  .k-switch-on:hover .k-switch-handle,
  .k-switch-on.k-state-hover .k-switch-handle {
    border: 2px solid #12b28c;
    background-color: white;
  }

  .k-switch-off .k-switch-handle {
    border: 2px solid #c7c7cc;
    background-color: white;
  }

  .k-switch-off:hover .k-switch-handle,
  .k-switch-off.k-state-hover .k-switch-handle {
    border: 2px solid #c7c7cc;
    background-color: white;
  }

  .k-switch-on .k-switch-container {
    color: #ffffff;
    background-color: #12b28c;
  }

  .k-switch-off .k-switch-container {
    color: #ffffff;
    background-color: #c7c7cc;
  }

  .k-switch-on:hover .k-switch-container,
  .k-switch-on.k-state-hover .k-switch-container {
    color: #ffffff;
    background-color: #0e8d70;
  }

  .k-switch-off:hover .k-switch-container,
  .k-switch-off.k-state-hover .k-switch-container {
    color: #ffffff;
    background-color: #acacb2;
  }
}

.glbSystemConfigurationModalStyle,
.glbSystemConfigurationModalStyleCustom {
  .modal-content,
  .modal-dialog {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    border: none;
    width: 90vw !important;
    border-radius: 9px !important;
  }
}

.requiredErrorMsgStyle {
  // font-size: 1.3rem;
  // color: red;
  color: red;
  font-style: italic;
  font-size: 12px;
  margin-top: 4px;
}

.k-grid-content {
  overflow-x: scroll;
}

.k-window-actions {
  border: none;
}

.searchTable {
  .k-grid .k-cell-inner > .k-link:hover {
    // background-color: rgb(35, 100, 48) !important;
    background-color: inherit;
    font-weight: bold !important;
    color: white !important;
  }

  .k-grid-filter {
    display: none;
  }

  .k-grid-filter:hover {
    background-color: #25a4db !important;
    color: white !important;
  }

  .k-grid-header .k-header {
    border-color: #f6f6f6 !important;
  }
}

// Start:- New search page scss
.k-tabstrip-top > .k-tabstrip-items-wrapper > .k-tabstrip-items {
  width: 100%;
  background-color: #ebebeb;
  border-radius: 6px;
  padding: 1rem 1.8rem 1rem 1rem;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  display: flex;
  justify-content: space-between;
}

.k-tabstrip-items .k-link {
  font-size: 1.5rem;
  color: #222 !important;
  font-weight: 800;
}

.k-tabstrip-items-wrapper .k-item.k-state-active,
.k-tabstrip-items-wrapper .k-item.k-state-selected {
  color: #000;
  background-color: #ebebeb !important;
  border-bottom: 3px solid #12a5e4 !important;
}

.k-tabstrip-items-wrapper:hover .k-item.k-state-active:hover,
.k-tabstrip-items-wrapper:hover .k-link:hover {
  background-color: #dee5ef !important;
}

.k-tabstrip-content,
.k-tabstrip > .k-content {
  background-color: #fff;
  border: 1px solid #a7a7a7 !important;
  border-top: none !important;
  border-radius: 0px 0px 10px 10px !important;
  margin: 0px;
  height: 500px !important;
}

.glbSearchPageModalStyle {
  .modal-dialog {
    max-width: 90vw !important;
  }

  .modal-content {
    border-radius: 9px !important;
  }
}

.glbUpdateFileCodeModalStyle {
  .modal-content,
  .modal-dialog {
    border: none;
    width: 80vw;
    border-radius: 9px;
  }
}

.glbUpdateHoldModalStyle {
  .modal-content,
  .modal-dialog {
    border: none;
    // width: 80vw;
    border-radius: 9px;
  }
}

// End:- New search page scss
.searchNoteStyle {
  font-size: 15px;
  font-weight: 400;
  font-style: italic;
}

.actionColumn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.textCenter {
  text-align: center !important;
  display: flex;
  justify-content: center;
  align-items: center;
  // height: 41px;
}

.glbModalHeader {
  .k-window-title {
    color: #484440;
    font-size: 27px;
    font-weight: 600;
    letter-spacing: 0.44px;
    padding-top: 0px;
  }

  .k-dialog-titlebar {
    border-bottom: 1.5px solid #d9d9d9 !important;
    padding: 15px 10px;
  }

  .k-window-actions {
    border: 3px solid #eb5757;
    border-radius: 50%;
    padding: 4px !important;
  }

  .k-button-icon {
    color: #eb5757 !important;
  }

  .k-icon {
    font-size: 25px;
  }

  .k-window-content {
    overflow-x: hidden;
  }
}
.glbRecordScheduleModal {
  .k-window-title {
    color: #484440;
    font-size: 27px;
    font-weight: 600;
    letter-spacing: 0.44px;
    padding-top: 0px;
  }
  .k-dialog-titlebar {
    border-bottom: 1.5px solid #d9d9d9 !important;
    padding: 15px 10px;
  }
  .k-window-actions {
    border: 3px solid #eb5757;
    border-radius: 50%;
    padding: 4px !important;
  }
  .k-button-icon {
    color: #eb5757 !important;
  }
  .k-icon {
    font-size: 25px;
  }
  .k-window-content {
    height: 78vh;
    overflow-x: hidden;
  }
}
.glbRecordScheduleSearchModal {
  .k-window-title {
    color: #484440;
    font-size: 27px;
    font-weight: 600;
    letter-spacing: 0.44px;
    padding-top: 0px;
  }
  .k-dialog-titlebar {
    border-bottom: 1.5px solid #d9d9d9 !important;
    padding: 15px 10px;
  }
  .k-window-actions {
    border: 3px solid #eb5757;
    border-radius: 50%;
    padding: 4px !important;
  }
  .k-button-icon {
    color: #eb5757 !important;
  }
  .k-icon {
    font-size: 25px;
  }
  .k-window-content {
    height: 64vh;
    overflow-x: hidden;
  }
}
// document version error modal
.glbErrorDocModal {
  .modal-dialog {
    max-width: 460px !important;
  }

  .modal-content {
    border-radius: 9px;
  }
}

.validation-message {
  color: red;
  font-style: italic;
  font-size: 12px;
  margin-top: 4px;
}

.success-message {
  color: #0fa14c;
  font-size: 12px;
  margin-top: 4px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
}

.primaryBtnStyle {
  border: none;
  border-radius: 3px;
  background: #1f5492;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: center;
  cursor: pointer;
}
.loaderText {
  position: absolute;
  top: 58%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  font-weight: 500;
}
.header-sort-icon {
  margin-left: 10px;
}
