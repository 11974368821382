.defultBtnStyle {
  background: white;
  color: #1f5492;
  border: 1px solid #1f5492;
  height: 4rem;
  width: 100%;
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  padding: 0rem 2rem;
  border-radius: 0.4rem;
  font-size: 1.5rem;
  letter-spacing: 0.05rem;
}

.keywordSearchBtn {
  background: #1f5492 !important;
  color: #fff !important;
  border-color: transparent !important;
  cursor: pointer;
}

.saveRecordBtn {
  background: #28a745 !important;
  color: white !important;
  border-color: transparent !important;
}
.clearAllBtnStyle {
  background: #eb5757 !important;
  color: #fff !important;
  border-color: transparent !important;
}
