@media (max-width: 1600px) {
    .keywordSearchInput {
        width: 550px !important;
    }
}

@media (max-width: 1350px) {
    .keywordSearchInput {
        width: 400px !important;
    }
}
.searchContent{
    display: flex;
    gap: 15;
}

.keywordSearchInput {
    height: 4rem;
    font-family: sans-serif;
    width: 600px;
    font-size: 16px;
    font-weight: 400;
    color: #757575;
    text-align: left;
    border-radius: 3px;
    letter-spacing: 0.02em;
    border: 1px solid #c8c7c6;
    font-family: sans-serif !important;
    line-height: 19px;
}

.keywordSearchBtn {
    border: none;
    border-radius: 3px;
    background: #1f5492;
    color: #fff;
    font-size: 17px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.02em;
    text-align: center;
    cursor: pointer;
}

.clearAllBtnStyle {
    background: #EB5757;
    border: 1px solid #EB5757;
    color: #FFF;
    border: none;
    border-radius: 3px;
    color: #fff;
    font-size: 17px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.02em;
    text-align: center;
    cursor: pointer;
}


button:disabled {
    opacity: 0.6;
}