.k-grid tr td {
  color: #494440;
  background-color: #ffffff;
  border: solid 1px #ebebeb;
}

.k-grid tr {
  border: solid rgb(8, 8, 8) 1px;
}

.k-checkbox:checked {
  border-color: #205493 !important;
  color: #ffffff;
  background-color: #205493 !important;
}

// For right CheckBox Start

// label{
//   display: block;
//    margin-bottom: 10px;
// }
.tableHeaderTitle {
  font-weight: 400;
  color: white;
  font-size: 16px;
  font-family: sans-serif;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.manageDisposition {
  input[type='radio'] {
    appearance: none;
    background-color: #fff;
    width: 15px;
    height: 15px;
    border: 2px solid #ccc;
    border-radius: 2px;
    display: inline-grid;
    place-content: center;
  }

  input[type='radio']::before {
    content: '';
    width: 10px;
    height: 10px;
    transform: scale(0);
    transform-origin: bottom left;
    background-color: #fff;
    clip-path: polygon(13% 50%, 34% 66%, 81% 2%, 100% 18%, 39% 100%, 0 71%);
  }

  input[type='radio']:checked::before {
    transform: scale(1);
  }

  input[type='radio']:checked {
    background-color: #0075ff;
    border: 2px solid #0075ff;
  }
}

// For right CheckBox end

.button-parent {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .button-child {
    margin: auto 2px;

    .btn {
      width: 75px;
      height: 35px;
      font-size: initial;
    }
  }
}

.disposition_modal_popup {
  .modal_header_section {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    .modal_header_panel_text {
      margin: 0;
      font-size: 24px;
      font-weight: 550;
      color: #484440;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.44px;
      font-family: sans-serif;
    }

    .modal_header_panel_icon {
      cursor: pointer;
    }
  }

  .disposition_modal_body_text {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #484440;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.44px;
  }

  .modal_footer_section {
    height: 53px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;

    .modal_footer_disposition_button {
      border: none;
      color: #fff;
      font-weight: 900;
      margin-right: 10px;
      border-radius: 5px;
      background-color: #1f5492;
    }

    .modal_footer_cancel_button {
      color: #1f5492;
      font-weight: 900;
      margin-left: 10px;
      border-radius: 5px;
      background-color: #fff;
      border: 1px solid #1f5492;
    }
  }
}
